// todo : ajouter un useState pour l'avis et la note, ajouter la review sur la prestation,
// ajouter le selecteur d'etoiles pour la note,
// si 4 ou 5 étoiles, rediriger vers laisser un avis sur google,
// sinon, afficher un message de remerciement.
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../firebase";
import { useAuth } from "../contexts/AuthContext";
import { Appointment } from "../utils/types";

const GetReview = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [appointment, setAppointment] = useState<Appointment | null>(null);
  const [rating, setRating] = useState<number>(0);
  const [hover, setHover] = useState<number | null>(null);
  const [review, setReview] = useState("");
  const [step, setStep] = useState(0);

  useEffect(() => {
    if (!currentUser) {
      navigate("/login?redirect=getreview/" + id);
    }
  }, []);

  useEffect(() => {
    const getrdv = async (clientid: string) => {
      if (!id) return;
      const docRef = doc(db, "appointments", id);
      const docSnap = await getDoc(docRef);
      const data = docSnap.data();
      if (!data) return;
      if (data.clientId === clientid) {
        setAppointment(data as Appointment);
      } else {
        navigate("/");
      }
    };
    const getClient = async () => {
      if (!currentUser) return;
      const clientsCol = collection(db, "clients");
      const q = query(clientsCol, where("email", "==", currentUser.email));
      const docSnap = await getDocs(q);
      const data = docSnap.docs[0].data();
      const clientid = data.id;
      getrdv(clientid);
    };
    getClient();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const reviewData = {
      ...appointment,
      review,
      rating,
    };
    if (!id) return;
    const docRef = doc(db, "appointments", id);
    await updateDoc(docRef, reviewData);
    setStep(1);
  };
  if (!appointment) return <div>Loading...</div>;
  return (
    <div className="w-10/12 mx-auto text-black min-h-[70vh] my-4">
      {step === 0 && (
        <>
          <h1 className="text-xl font-bold">
            Qu'avez-vous pensez de votre {appointment.name} ?{" "}
          </h1>
          <div className="flex flex-col space-y-4">
            <div className="flex flex-col space-y-2">
              <label htmlFor="review">Votre avis : </label>
              <textarea
                name="review"
                id="review"
                cols={30}
                rows={10}
                className="border border-gray-300 p-2"
                value={review}
                onChange={(e) => setReview(e.target.value)}
              ></textarea>
            </div>
            <div className="flex flex-col space-y-2">
              <label htmlFor="note">Votre note : </label>

              <div className="flex">
                {[...Array(5)].map((_, index) => {
                  const currentRating = index + 1;

                  return (
                    <label key={index}>
                      <input
                        type="radio"
                        name="rating"
                        className="hidden"
                        value={currentRating}
                        onChange={() => setRating(currentRating)}
                      />
                      <span
                        className="star cursor-pointer text-[2rem] m-[5px]"
                        style={{
                          color:
                            currentRating <= (hover || rating)
                              ? "#ffc107"
                              : "#e4e5e9",
                        }}
                        onMouseEnter={() => setHover(currentRating)}
                        onMouseLeave={() => setHover(null)}
                      >
                        &#9733;
                      </span>
                    </label>
                  );
                })}
              </div>
            </div>
            <button
              className="btn text-white hover:bg-slate-800"
              onClick={handleSubmit}
            >
              Envoyer
            </button>
          </div>
        </>
      )}

      {step === 1 && (
        <div className="flex flex-col items-center">
          <h1 className="text-xl font-bold">Merci pour votre avis</h1>
          {rating && rating >= 4 ? (
            <button
              className="btn text-white bg-black hover:bg-slate-800"
              onClick={() => {
                window.open(
                  "https://www.google.com/search?q=avis+sur+tadlik",
                  "_blank"
                );
              }}
            >
              Laisser un avis sur Google
            </button>
          ) : (
            <p>Merci pour votre avis</p>
          )}
        </div>
      )}
    </div>
  );
};

export default GetReview;
