import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../../firebase";
import { useAuth } from "../contexts/AuthContext";
import { sendEmail } from "../utils/mailer";
import { Order } from "../utils/types";
const SuccessPage = () => {
  const [cardDetails, setCardDetails] = useState({ cardType: "", last4: "" });
  const [order, setOrder] = useState<Order | null>(null);
  const { currentUser } = useAuth();

  useEffect(() => {
    // Extraire l'ID de session de l'URL
    localStorage.removeItem("order");
    const queryParams = new URLSearchParams(window.location.search);
    const sessionId = queryParams.get("session_id");

    if (sessionId) {
      const getOrderFromFirebase = async () => {
        const orderCol = collection(db, "commandes");
        const q = query(orderCol, where("sessionId", "==", sessionId));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            const data = { ...doc.data(), id: doc.id } as Order;
            setOrder(data);
            updateOrderStatus(doc.id);
            fetchCardDetails(sessionId);
            if (!data.invoiceUrl) {
              generateInvoice(data);
            }
            if (!currentUser) return;
            const emailData = {
              toEmail: currentUser.email || "",
              templateId: "d-345e54ff75f74515a0195f7bd48df89e",
              templateData: {
                title: "Votre commande est confirmée!",
                subject: "Votre commande est confirmée!",
                text: `Bonjour, ${currentUser.displayName} ! Votre commande a bien été enregistrée. Vous pouvez consulter les détails de votre réservation en vous connectant à votre compte Tadlik. Merci de votre confiance. A bientôt!`,
              },
            };
            sendEmail(emailData);
          });
        }
      };

      getOrderFromFirebase();
    }
  }, []);

  const generateInvoice = async (order: Order) => {
    try {
      const response = await fetch(
        import.meta.env.VITE_API_URL + "/makeInvoice",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(order), // Envoie les données de la commande
        }
      );
      const invoiceData = await response.json();
      if (invoiceData.url) {
        // Redirection vers l'URL de la facture ou gestion de l'affichage
        updateDoc(doc(db, "commandes", order.id), {
          invoiceUrl: invoiceData.url,
        });

        //navigate(invoiceData.invoiceUrl);
      }
    } catch (error) {
      console.error("Erreur lors de la génération de la facture :", error);
    }
  };

  const updateOrderStatus = async (orderId: string) => {
    if (orderId) {
      const orderRef = doc(db, "commandes", orderId);
      await updateDoc(orderRef, { status: "payé" });
    }
  };

  const fetchCardDetails = (sessionId: string) => {
    fetch(
      import.meta.env.VITE_API_URL + `/retrieve-session?session_id=${sessionId}`
    )
      .then((response) => response.json())
      .then((data) => {
        setCardDetails(data);
      });
  };

  if (!order) {
    return <p>Chargement...</p>;
  }

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-3xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
        <div className="max-w-xl">
          <h1 className="text-base font-medium text-slate-900">Merci!</h1>
          <p className="mt-2 text-4xl font-bold tracking-tight sm:text-5xl">
            Votre commande est en route!
          </p>

          <dl className="mt-12 text-sm font-medium">
            <dt className="text-gray-900">Numéro de commande</dt>
            <dd className="mt-2 text-slate-900 uppercase">
              <Link to={""}>#{order.id}</Link>
            </dd>
          </dl>
        </div>

        <div className="mt-10 border-t border-gray-200">
          <h2 className="sr-only">Your order</h2>

          <h3 className="sr-only">Items</h3>
          {order.products.map((product) => (
            <div
              key={product.id}
              className="flex space-x-6 border-b border-gray-200 py-10"
            >
              <img
                src={product.images[0].url}
                className="h-20 w-20 flex-none rounded-lg bg-gray-100 object-cover object-center sm:h-40 sm:w-40"
              />
              <div className="flex flex-auto flex-col">
                <div>
                  <h4 className="font-medium text-gray-900">{product.name}</h4>
                  <p className="mt-2 text-sm text-gray-600">
                    {product.description}
                  </p>
                </div>
                <div className="mt-6 flex flex-1 items-end">
                  <dl className="flex space-x-4 divide-x divide-gray-200 text-sm sm:space-x-6">
                    <div className="flex">
                      <dt className="font-medium text-gray-900">Quantité</dt>
                      <dd className="ml-2 text-gray-700">{product.quantity}</dd>
                    </div>
                    <div className="flex pl-4 sm:pl-6">
                      <dt className="font-medium text-gray-900">Prix</dt>
                      <dd className="ml-2 text-gray-700">{product.price} €</dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          ))}

          <div className="sm:px-6">
            <h3 className="sr-only">Vos informations</h3>

            <h4 className="sr-only">Addresses</h4>
            <dl className="grid grid-cols-2 gap-x-6 py-10 text-sm">
              <div>
                <dt className="font-medium text-gray-900">
                  Adresse de livraison
                </dt>
                <dd className="mt-2 text-gray-700">
                  <address className="not-italic">
                    <span className="block">
                      {order.LivraisonInfos.nom} {order.LivraisonInfos.prenom}
                    </span>
                    <span className="block">
                      {order.LivraisonInfos.adresse}
                    </span>
                    <span className="block">
                      {order.LivraisonInfos.ville},{" "}
                      {order.LivraisonInfos.codePostal}
                    </span>
                  </address>
                </dd>
              </div>
              <div>
                <dt className="font-medium text-gray-900">
                  Adresse de facturaction
                </dt>
                <dd className="mt-2 text-gray-700">
                  <address className="not-italic">
                    <span className="block">
                      {order.LivraisonInfos.nom} {order.LivraisonInfos.prenom}
                    </span>
                    <span className="block">
                      {order.LivraisonInfos.adresse}
                    </span>
                    <span className="block">
                      {order.LivraisonInfos.ville},{" "}
                      {order.LivraisonInfos.codePostal}
                    </span>
                  </address>
                </dd>
              </div>
            </dl>

            <h4 className="sr-only">Payment</h4>
            <dl className="grid grid-cols-2 gap-x-6 border-t border-gray-200 py-10 text-sm">
              <div>
                <dt className="font-medium text-gray-900">
                  Méthode de paiement
                </dt>
                <dd className="mt-2 text-gray-700">
                  <p className="uppercase">{cardDetails.cardType}</p>
                  <p>
                    <span aria-hidden="true">••••</span>
                    <span className="sr-only">finis par </span>
                    {cardDetails.last4}
                  </p>
                </dd>
              </div>
              <div></div>
            </dl>

            <h3 className="sr-only">Résumé</h3>

            <dl className="space-y-6 border-t border-gray-200 pt-10 text-sm">
              <div className="flex justify-between">
                <dt className="font-medium text-gray-900">Sous-total</dt>
                <dd className="text-gray-700">{order.subtotal} €</dd>
              </div>
              {/* <div className="flex justify-between">
                <dt className="flex font-medium text-gray-900">
                  Discount
                  <span className="ml-2 rounded-full bg-gray-200 px-2 py-0.5 text-xs text-gray-600">
                    STUDENT50
                  </span>
                </dt>
                <dd className="text-gray-700">-$18.00 (50%)</dd>
              </div> */}
              {/* {order.shippingFees && (
                <div className="flex justify-between">
                  <dt className="font-medium text-gray-900">Shipping</dt>
                  <dd className="text-gray-700">5.00 €</dd>
                </div>
              )} */}
              <div className="flex justify-between">
                <dt className="font-medium text-gray-900">Total</dt>
                <dd className="text-gray-900">{order.total} €</dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
