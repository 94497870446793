const Icstest = () => {
  const handleIcs = async () => {
    const response = await fetch("http://localhost:3000/event-ics", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        title: "Test",
        description: "Test",
        start: [2024, 5, 4, 14, 0],
        end: [2024, 5, 4, 15, 0],
      }),
    });

    if (response.ok) {
      // Récupérer la réponse en tant que blob
      const blob = await response.blob();
      // Créer une URL pour le blob
      const url = window.URL.createObjectURL(blob);
      // Créer un élément <a> pour déclencher le téléchargement
      const a = document.createElement("a");
      a.href = url;
      a.download = "event.ics"; // Nom du fichier téléchargé
      document.body.appendChild(a);
      a.click();
      // Libérer l'URL après utilisation
      window.URL.revokeObjectURL(url);
    } else {
      console.error("Erreur lors de la création du fichier iCal");
    }
  };
  return (
    <div>
      <button onClick={handleIcs}>Click me</button>
    </div>
  );
};

export default Icstest;
