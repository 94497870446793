import { RadioGroup, Tab } from "@headlessui/react";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../firebase";
import { Service, ServiceDuration } from "../utils/types";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const ServiceDetails = () => {
  const { id } = useParams();
  const [selectedDuration, setSelectedDuration] = useState<ServiceDuration>();
  const [selectedPayment, setSelectedPayment] = useState("offline");
  const [product, setProduct] = useState<Service>();
  const [codeCoupon, setCodeCoupon] = useState("");
  const [codePromo, setCodePromo] = useState("");
  const [promoprix, setPromoPrix] = useState("");
  const [errPromo, setErrPromo] = useState(false);

  const navigate = useNavigate(); // Ajoutez ceci

  const resaBtnStatsCounter = () => {
    if (import.meta.env.MODE !== "production") {
      return;
    }

    const statsCollection = collection(db, "stats");
    const data = {
      name: "service details resa btn clicked",
      date: Timestamp.now(),
    };
    addDoc(statsCollection, data);
  };

  // Ajoutez la fonction pour gérer le changement de paiement et de code de coupon
  const handleReservationClick = () => {
    resaBtnStatsCounter();
    let reservationPath = "";
    if (selectedPayment === "gift") {
      reservationPath = `gift/${id}/${selectedDuration?.dur}/${codePromo}`;
    } else {
      reservationPath = `reservation?id=${id}&duration=${selectedDuration?.dur}&payment=${selectedPayment}`;
      if (selectedPayment === "coupon" && codeCoupon !== "") {
        reservationPath += `&codecoupon=${codeCoupon}`; // Ajoutez le code du coupon à l'URL
      } else if (codePromo && codePromo !== "") {
        reservationPath += `&codePromo=${codePromo}`;
      } else if (selectedPayment === "coupon" && codeCoupon === "") {
        alert("Veuillez entrer le code de votre coupon");
        return;
      }
    }

    navigate("/" + reservationPath); // Redirigez l'utilisateur vers l'URL mise à jour
  };

  const checkCodePromo = async () => {
    if (!selectedDuration) return;
    let prix = parseFloat(selectedDuration.price);
    if (codePromo !== "") {
      const q = query(
        collection(db, "codesPromos"),
        where("code", "==", codePromo)
      );
      const snapshot = await getDocs(q);
      if (!snapshot.empty) {
        snapshot.forEach((doc) => {
          const coupon = doc.data();
          prix = prix - (prix * parseFloat(coupon.reduction)) / 100;
        });
        setPromoPrix(prix.toFixed(2));
        setErrPromo(false);
      } else {
        //alert("Code promo invalide");
        setPromoPrix("");
        setCodePromo("");
        setErrPromo(true);
      }
    } else {
      setPromoPrix("");
      setErrPromo(false);
    }
  };

  useEffect(() => {
    const getService = async () => {
      if (!id) return;
      const docRef = doc(db, "prestations", id);
      getDoc(docRef)
        .then((doc) => {
          if (doc.exists()) {
            const data = doc.data();
            setProduct(data as Service);
            setSelectedDuration(data.durations[0]);
          }
        })
        .catch((error) => {
          console.error("Error getting document:", error);
        });
    };
    getService();
  }, [id]);

  useEffect(() => {
    checkCodePromo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDuration]);

  // récupérer les données de la prestation
  if (!product) return <p>loading...</p>;
  return (
    <div className="bg-white">
      <div className="mx-auto px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        {/* Product */}
        <div className="lg:grid lg:grid-cols-7 lg:grid-rows-1 lg:gap-x-8 lg:gap-y-10 xl:gap-x-16">
          {/* Product image */}
          <div className="lg:col-span-4 lg:row-end-1">
            <div className="aspect-h-3 aspect-w-4 overflow-hidden rounded-lg bg-gray-100">
              {/*  */}
              <Tab.Group as="div" className="flex flex-col-reverse p-2">
                {/* Image selector */}
                <div className="mx-auto mt-6 w-full max-w-2xl sm:block lg:max-w-none">
                  <Tab.List className="grid grid-cols-4 gap-3 p-4">
                    {product.images &&
                      product.images.map((image, index) => (
                        <Tab
                          key={index}
                          className="relative flex h-20 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                        >
                          {({ selected }) => (
                            <>
                              <span className="sr-only">{image.name}</span>
                              <span className="absolute inset-0 overflow-hidden rounded-md">
                                {image.type == "image" ? (
                                  <img
                                    src={image.url}
                                    alt=""
                                    className="h-full w-full object-cover object-center"
                                  />
                                ) : (
                                  <video
                                    src={image.url}
                                    loop={false}
                                    className="h-full w-full object-cover object-center"
                                  />
                                )}
                              </span>
                              <span
                                className={classNames(
                                  selected
                                    ? "ring-indigo-500"
                                    : "ring-transparent",
                                  "pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2"
                                )}
                                aria-hidden="true"
                              />
                            </>
                          )}
                        </Tab>
                      ))}
                  </Tab.List>
                </div>

                <Tab.Panels className="aspect-h-1 aspect-w-1 w-full">
                  {product.images &&
                    product.images.map((image, index) => (
                      <Tab.Panel key={index}>
                        {image.type == "image" ? (
                          <img
                            src={image.url}
                            alt={image.alt}
                            className="h-full w-full object-cover object-center sm:rounded-lg"
                          />
                        ) : (
                          <video
                            src={image.url}
                            controls={true}
                            loop={false}
                            className="h-full w-full object-cover object-center"
                          />
                        )}
                      </Tab.Panel>
                    ))}
                </Tab.Panels>
              </Tab.Group>
            </div>
          </div>

          {/* Product details */}
          <div className="mx-auto mt-14 max-w-2xl sm:mt-16 lg:col-span-3 lg:row-span-2 lg:row-end-2 lg:mt-0 lg:max-w-none">
            <div className="flex flex-col-reverse">
              <div className="mt-4">
                <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                  {product.name}
                </h1>
              </div>

              {/* {reviews && (
                <div>
                  <h3 className="sr-only">Reviews</h3>
                  {reviews?.rating && (
                    <div className="flex items-center">
                      {[0, 1, 2, 3, 4].map((rating) => (
                        <StarIcon
                          key={rating}
                          className={classNames(
                            reviews?.average > rating
                              ? "text-yellow-400"
                              : "text-gray-300",
                            "h-5 w-5 flex-shrink-0"
                          )}
                          aria-hidden="true"
                        />
                      ))}
                    </div>
                  )}
                  <p className="sr-only">{reviews?.average} sur 5 étoiles</p>
                </div>
              )} */}
            </div>

            <p className="mt-6 text-gray-500">{product?.description}</p>

            <div className="mt-8">
              <div className="flex items-center justify-between">
                <h2 className="text-sm font-medium text-gray-900">Durée</h2>
              </div>

              <RadioGroup
                value={selectedDuration}
                onChange={setSelectedDuration}
                className="mt-2"
              >
                <RadioGroup.Label className="sr-only">
                  Choisissez une durée
                </RadioGroup.Label>
                <div className="grid grid-cols-3 gap-3 sm:grid-cols-6">
                  {product.durations &&
                    product.durations.map((duration, idx) => (
                      <RadioGroup.Option
                        key={idx}
                        value={duration}
                        className={({ active, checked }) =>
                          classNames(
                            active ? "ring-2 ring-black ring-offset-2" : "",
                            checked
                              ? "border-transparent bg-black text-white hover:bg-black"
                              : "border-gray-200 bg-white text-gray-900 hover:bg-gray-50",
                            "flex items-center justify-center rounded-md border py-3 px-3 text-sm font-medium sm:flex-1 transition ease-in-out delay-150 hover:scale-110"
                          )
                        }
                      >
                        <RadioGroup.Label as="span">
                          {duration.dur}
                        </RadioGroup.Label>
                      </RadioGroup.Option>
                    ))}
                </div>
              </RadioGroup>
            </div>

            <div className="mt-8">
              <div className="flex items-center justify-between">
                <h2 className="text-sm font-medium text-gray-900">
                  Paiement de la prestation
                </h2>
              </div>

              <RadioGroup
                value={selectedPayment}
                onChange={setSelectedPayment}
                className="mt-2"
              >
                <RadioGroup.Label className="sr-only">
                  Choisissez une durée
                </RadioGroup.Label>
                <div className="grid grid-cols-2 gap-3 sm:grid-cols-4">
                  <RadioGroup.Option
                    value="offline"
                    className={({ active, checked }) =>
                      classNames(
                        active ? "ring-2 ring-black ring-offset-2" : "",
                        checked
                          ? "border-transparent bg-black text-white hover:bg-black"
                          : "border-gray-200 bg-white text-gray-900 hover:bg-gray-50",
                        "flex items-center justify-center rounded-md border py-3 px-3 text-sm font-medium sm:flex-1 transition ease-in-out delay-150 hover:scale-110"
                      )
                    }
                  >
                    <RadioGroup.Label as="span">Sur place</RadioGroup.Label>
                  </RadioGroup.Option>

                  <RadioGroup.Option
                    value="online"
                    className={({ active, checked }) =>
                      classNames(
                        active ? "ring-2 ring-black ring-offset-2" : "",
                        checked
                          ? "border-transparent bg-black text-white hover:bg-black"
                          : "border-gray-200 bg-white text-gray-900 hover:bg-gray-50",
                        "flex items-center justify-center rounded-md border py-3 px-3 text-sm font-medium sm:flex-1  transition ease-in-out delay-150 hover:scale-110"
                      )
                    }
                  >
                    <RadioGroup.Label as="span">En ligne</RadioGroup.Label>
                  </RadioGroup.Option>

                  <RadioGroup.Option
                    value="coupon"
                    className={({ active, checked }) =>
                      classNames(
                        active ? "ring-2 ring-black ring-offset-2" : "",
                        checked
                          ? "border-transparent bg-black text-white hover:bg-black"
                          : "border-gray-200 bg-white text-gray-900 hover:bg-gray-50",
                        "flex items-center justify-center rounded-md border py-3 px-3 text-sm font-medium sm:flex-1 transition ease-in-out delay-150 hover:scale-110"
                      )
                    }
                  >
                    <RadioGroup.Label as="span">
                      Code de sécurité
                    </RadioGroup.Label>
                  </RadioGroup.Option>

                  <RadioGroup.Option
                    value="gift"
                    className={({ active, checked }) =>
                      classNames(
                        active ? "ring-2 ring-black ring-offset-2" : "",
                        checked
                          ? "border-transparent bg-black text-white hover:bg-black"
                          : "border-gray-200 bg-white text-gray-900 hover:bg-gray-50",
                        "flex items-center justify-center rounded-md border py-3 px-3 text-sm font-medium sm:flex-1 transition ease-in-out delay-150 hover:scale-110"
                      )
                    }
                  >
                    <RadioGroup.Label as="span">Offrir</RadioGroup.Label>
                  </RadioGroup.Option>
                </div>
              </RadioGroup>

              <div className="mt-4">
                {selectedPayment === "offline" && (
                  <p className="text-sm text-gray-500">
                    Vous pouvez payer sur place en espèces ou par carte
                    bancaire.
                    <br />
                    <br />
                    Si vous bénéficiez d'une carte cadeau ou d'un bon d'un
                    partenaire, veuillez séléctionner l'option code sécurité et
                    entrer votre code dans le champ "code de sécurité".
                  </p>
                )}
                <br />
                {selectedPayment === "coupon" && (
                  <>
                    <label className="text-black text-sm font-medium leading-6">
                      Code de sécurité
                    </label>
                    <input
                      type="text"
                      value={codeCoupon}
                      maxLength={13}
                      className="block w-3/4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Entrez le code de votre coupon ici"
                      onChange={(e) => setCodeCoupon(e.target.value)}
                    />
                  </>
                )}
                {selectedPayment !== "coupon" && (
                  <>
                    <label className="text-black text-sm font-medium leading-6">
                      Code promo
                    </label>
                    <div className="flex gap-4">
                      <input
                        type="text"
                        value={codePromo}
                        maxLength={13}
                        className="block w-3/4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Entrez votre code promo ici"
                        onChange={(e) => setCodePromo(e.target.value)}
                      />
                      <button
                        className="text-sm text-white bg-black px-3 py-2 rounded-md"
                        onClick={checkCodePromo}
                      >
                        {" "}
                        Ajouter
                      </button>
                    </div>
                  </>
                )}
                {selectedPayment === "coupon" && codeCoupon.length >= 13 && (
                  <p className="text-sm text-red-500">
                    la taille du code de coupon ne doit pas dépasser 13
                    caractères.
                  </p>
                )}
              </div>
            </div>

            {selectedDuration && selectedDuration !== undefined && (
              <div className="mt-8">
                <div className="flex items-center justify-between">
                  <h2 className="text-sm text-gray-900">
                    Prix :{" "}
                    {(promoprix === "" || promoprix === "0") && (
                      <span className="font-medium">
                        {selectedDuration.price} €
                      </span>
                    )}
                    {promoprix !== "" && promoprix !== "0" && (
                      <span className="font-medium line-through text-slate-300">
                        {selectedDuration.price} €
                      </span>
                    )}
                    {promoprix && (
                      <span className="font-medium px-4">{promoprix} €</span>
                    )}
                    {errPromo && (
                      <span className="text-red-500 px-4">
                        Code promo invalide
                      </span>
                    )}
                  </h2>
                </div>
              </div>
            )}

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4">
              <button
                onClick={handleReservationClick}
                className="flex w-full items-center justify-center rounded-md border border-transparent bg-black px-8 py-3 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 focus:ring-offset-gray-50 transition ease-in-out delay-150 hover:scale-110"
              >
                Réserver
              </button>
            </div>
          </div>

          <div className="mx-auto mt-16 w-full max-w-2xl lg:col-span-7 lg:mt-0 lg:max-w-none">
            {/* <Tab.Group as="div">
              <div className="border-b border-gray-200">
                <Tab.List className="-mb-px flex space-x-8">
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        selected
                          ? "border-indigo-600 text-indigo-600"
                          : "border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800",
                        "whitespace-nowrap border-b-2 py-6 text-sm font-medium"
                      )
                    }
                  >
                    Customer Reviews
                  </Tab>
                  <Tab
                    className={({ selected }) =>
                      classNames(
                        selected
                          ? "border-indigo-600 text-indigo-600"
                          : "border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800",
                        "whitespace-nowrap border-b-2 py-6 text-sm font-medium"
                      )
                    }
                  >
                    FAQ
                  </Tab>
                </Tab.List>
              </div>
              <Tab.Panels as={Fragment}>
                {/*  <Tab.Panel className="-mb-10">
                  <h3 className="sr-only">Customer Reviews</h3>

                  {reviews?.featured?.map((review, reviewIdx) => (
                    <div
                      key={review.id}
                      className="flex space-x-4 text-sm text-gray-500"
                    >
                      <div className="flex-none py-10">
                        <img
                          src={review.avatarSrc}
                          alt=""
                          className="h-10 w-10 rounded-full bg-gray-100"
                        />
                      </div>
                      <div
                        className={classNames(
                          reviewIdx === 0 ? "" : "border-t border-gray-200",
                          "py-10"
                        )}
                      >
                        <h3 className="font-medium text-gray-900">
                          {review.author}
                        </h3>
                        <p>
                          <time dateTime={review.datetime}>{review.date}</time>
                        </p>

                        <div className="mt-4 flex items-center">
                          {[0, 1, 2, 3, 4].map((rating) => (
                            <StarIcon
                              key={rating}
                              className={classNames(
                                review.rating > rating
                                  ? "text-yellow-400"
                                  : "text-gray-300",
                                "h-5 w-5 flex-shrink-0"
                              )}
                              aria-hidden="true"
                            />
                          ))}
                        </div>
                        <p className="sr-only">
                          {review.rating} out of 5 stars
                        </p>

                        <div
                          className="prose prose-sm mt-4 max-w-none text-gray-500"
                          dangerouslySetInnerHTML={{ __html: review.content }}
                        />
                      </div>
                    </div>
                  ))}
                </Tab.Panel>

                <Tab.Panel className="text-sm text-gray-500">
                  <h3 className="sr-only">Frequently Asked Questions</h3>

                  <dl>
                    {faqs.map((faq) => (
                      <Fragment key={faq.question}>
                        <dt className="mt-10 font-medium text-gray-900">
                          {faq.question}
                        </dt>
                        <dd className="prose prose-sm mt-2 max-w-none text-gray-500">
                          <p>{faq.answer}</p>
                        </dd>
                      </Fragment>
                    ))}
                  </dl>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetails;
