/* eslint-disable @typescript-eslint/no-explicit-any */
import { addDoc, collection, Timestamp } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { db } from "../../firebase";
import { FbOrm } from "../utils/fborm";
import { LogDiscord } from "../utils/LogDiscord";
import { sendEmail } from "../utils/mailer";
import { Client } from "../utils/types";

const resCol = collection(db, "appointments");
const dataStr = localStorage.getItem("currentReservation");

const SuccessAppt = () => {
  const [searchParams] = useSearchParams();
  const session_id = searchParams.get("session_id");
  const payment_intent_id = searchParams.get("payment_intent");
  const addedDocId = useRef("");
  const [error, setError] = useState("");

  const handleAddReservation = async () => {
    if (!dataStr) {
      setError("Aucune réservation trouvé");
      return;
    }

    const data = JSON.parse(dataStr);
    const { startDate, endDate } = formatTimestamps(data);

    let paymentIntentId = "";

    if (data.payment === "coupon" || data.codeCoupon) {
      paymentIntentId = "COUPON_USED"; // Utiliser un identifiant fictif ou le vrai si nécessaire
    } else {
      // Traitement pour les autres méthodes de paiement nécessitant des détails de session
      if (data.sessionId || session_id) {
        paymentIntentId = await fetchPaymentIntentId(
          data.sessionId || session_id
        );
      } else if (!payment_intent_id) {
        setError(
          "Détails de paiement manquants pour la méthode de paiement spécifiée."
        );
        LogDiscord(
          "Détails de paiement manquants pour la méthode de paiement spécifiée.",
          "SuccessAppt.jsx"
        );
        LogDiscord(
          "```json\n" + JSON.stringify(data) + "```",
          "SuccessAppt.jsx"
        );
        return;
      } else {
        paymentIntentId = payment_intent_id;
      }
    }

    const clientData = await fetchClientData(data.clientEmail || "");

    if (!clientData) {
      setError("Client non trouvé pour l'email fourni.");
      return;
    }

    try {
      const reservationData = prepareReservationData(
        data,
        startDate,
        endDate,
        paymentIntentId,
        clientData
      );

      const addedDoc = await addDoc(resCol, reservationData);

      addedDocId.current = addedDoc.id;

      localStorage.removeItem("currentReservation");

      LogDiscord("Réservation ajoutée avec succès", "SuccessAppt.jsx");

      const dataForDiscord =
        "```json\n" + JSON.stringify(reservationData, null, 2) + "\n```";

      LogDiscord(dataForDiscord, "SuccessAppt.jsx");

      sendConfirmationEmails(clientData, reservationData, startDate, endDate);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error("Error adding document: ", error);

      setError(`Erreur lors de l'ajout de la réservation: ${error.message}`);

      LogDiscord(
        `Erreur lors de l'ajout d'un rdv: ${error}`,
        "SuccessAppt.jsx"
      );
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formatTimestamps = (data: any) => ({
    startDate: new Timestamp(
      data.datedebut.seconds,
      data.datedebut.nanoseconds
    ),
    endDate: new Timestamp(data.datefin.seconds, data.datefin.nanoseconds),
  });

  const fetchPaymentIntentId = async (sessionId: string) => {
    if (!sessionId) return "";
    try {
      const response = await fetch(
        `${
          import.meta.env.VITE_API_URL
        }/retrieve-session?session_id=${sessionId}`
      );
      const session = await response.json();
      return session.payment_intent.id;
    } catch (error) {
      console.error("Error getting payment intent: ", error);
      return "";
    }
  };

  const fetchClientData = async (email: string) => {
    try {
      const clientData = await FbOrm([
        {
          name: "clients",
          where: [{ field: "email", operator: "==", value: email }],
        },
      ]);
      const resClientArr = clientData.clients as Client[];

      return resClientArr[0] || null;
    } catch (error) {
      console.error("Error fetching client data: ", error);
      return null;
    }
  };

  const prepareReservationData = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any,
    startDate: Timestamp,
    endDate: Timestamp,
    paymentIntentId: string,
    client: Client
  ) => ({
    ...data,
    datedebut: startDate,
    datefin: endDate,
    status: "payed",
    emailReviewSent: false,
    paymentIntentId,
    camp: localStorage.getItem("camp") || "",
    clientId: client.id,
    createdAt: Timestamp.now(),
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sendConfirmationEmails = (
    client: Client,
    reservationData: any,
    startDate: Timestamp,
    endDate: Timestamp
  ) => {
    const EmailResaDateDebut = reservationData.datedebut
      .toDate()
      .toLocaleDateString("fr-FR", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
    const EmailResaDateFin = reservationData.datefin
      .toDate()
      .toLocaleDateString("fr-FR", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      });

    // Google Calendar link
    const googleCalendarUrl = generateGoogleCalendarLink(
      reservationData,
      startDate,
      endDate
    );

    // Create ICS file for Apple Calendar
    const emailData = {
      toEmail: client.email,
      templateId: "d-345e54ff75f74515a0195f7bd48df89e",
      templateData: {
        title: "Confirmation de votre réservation chez Tadlik",
        subject: "Confirmation de réservation",
        text: `Bonjour ${client.firstname}, votre réservation pour ${reservationData.name} le ${EmailResaDateDebut} au ${EmailResaDateFin} a bien été enregistrée.
        Si vous souhaitez ajouter cet événement à votre calendrier, vous pouvez le faire en cliquant sur le lien suivant : <a href="${googleCalendarUrl}">google calendar</a>`,
      },
    };
    sendEmail(emailData);
    const emailDataAdmin = {
      toEmail: "20tadlik@gmail.com",
      templateId: "d-345e54ff75f74515a0195f7bd48df89e",
      templateData: {
        title: "Confirmation de votre réservation chez Tadlik",
        subject: "Confirmation de réservation",
        text: `Bonjour Lakhdar, une réservation à été effectué sur tadlik ! details : ${reservationData.name} pour ${client.lastname} ${client.firstname}, le ${EmailResaDateDebut} au ${EmailResaDateFin} `,
      },
    };
    sendEmail(emailDataAdmin);
  };

  const generateGoogleCalendarLink = (
    reservationData: any,
    startDate: Timestamp,
    endDate: Timestamp
  ) => {
    const startDateStr = startDate
      .toDate()
      .toISOString()
      .replace(/-|:|\.\d+/g, ""); // Format attendu par Google
    const endDateStr = endDate
      .toDate()
      .toISOString()
      .replace(/-|:|\.\d+/g, "");

    const location = "35 cours d'herbouville 69004 lyon";

    return `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
      reservationData.name
    )}&dates=${startDateStr}/${endDateStr}&details=${encodeURIComponent(
      reservationData.description
    )}&location=${encodeURIComponent(location)}&sf=true&output=xml`;
  };

  useEffect(() => {
    const abort = new AbortController();
    handleAddReservation();
    return () => {
      abort.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="min-h-[70vh] text-black bg-white">
      {error ? (
        <h1 className="text-4xl text-center pt-20 text-red-500">{error}</h1>
      ) : (
        <>
          <h1 className="text-4xl text-center pt-20">
            Réservation confirmée 🎉
          </h1>
          <p className="text-center mt-4">
            Votre réservation a bien été enregistrée. Vous recevrez un email de
            confirmation dans quelques instants.
          </p>
        </>
      )}
    </div>
  );
};

export default SuccessAppt;
