import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import Product from "../components/Product";
import Service from "../components/Service";
import {
  CategoryType,
  Product as ProductType,
  Service as ServiceType,
  UserType,
} from "../utils/types";

type PrestataireType = UserType & {
  password?: string;
};

const PagePrestataire = () => {
  const { id } = useParams();
  const [prestataire, setPrestataire] = useState<PrestataireType>();
  const [prestations, setPrestations] = useState<ServiceType[]>([]);
  const [categories, setCategories] = useState<CategoryType[]>([]);
  const [produits, setProduits] = useState<ProductType[]>([]);
  const [openCategory, setOpenCategory] = useState<string | undefined>(
    undefined
  );
  useEffect(() => {
    const getDocPrestataire = async () => {
      if (!id) return;
      const docRef = doc(db, "admins", id);
      const docSnap = await getDoc(docRef);
      const data = { ...docSnap.data(), id: docSnap.id } as PrestataireType;
      delete data.password;
      setPrestataire(data);
    };
    const getCategories = async () => {
      const categoriesCol = collection(db, "categories");
      const q = query(
        categoriesCol,
        where("id_prestataire", "==", id),
        where("isEnabled", "==", true)
      );
      const categoriesSnap = await getDocs(q);
      const categoriesData = categoriesSnap.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      setCategories(categoriesData as CategoryType[]);
    };
    const getPrestations = async () => {
      const prestationsCol = collection(db, "prestations");
      const q = query(prestationsCol, where("id_prestataire", "==", id));
      const prestationsSnap = await getDocs(q);
      const prestationsData = prestationsSnap.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      setPrestations(prestationsData as ServiceType[]);
    };

    const getProduits = async () => {
      const produitsCol = collection(db, "produits");
      const q = query(produitsCol, where("idPrestataire", "==", id));
      const produitsSnap = await getDocs(q);
      const produitsData = produitsSnap.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      setProduits(produitsData as ProductType[]);
    };
    getDocPrestataire();
    getCategories();
    getPrestations();
    getProduits();
  }, [id]);

  if (!prestataire) return <div>Chargement...</div>;
  return (
    <div className="text-black w-10/12 mx-auto min-h-[70vh]">
      {/* section à propos de moi */}
      <div className="my-4 bg-slate-100 p-4 rounded gap-4 grid grid-cols-1 md:grid-cols-8">
        <div className="">
          {prestataire.imageUrl !== "" ? (
            <img
              className="mx-auto h-[140px] w-[140px] rounded-full"
              src={prestataire.imageUrl}
              alt=""
            />
          ) : (
            <div className="mx-auto h-[140px] w-[140px] rounded-full bg-gray-300 px-2 flex items-center justify-center">
              <p className="text-3xl">
                {prestataire.name ? prestataire.name[0] : ""}
              </p>
            </div>
          )}
        </div>
        <div className="md:col-span-6 self-center">
          <h1 className="text-xl font-bold">À propos de moi : </h1>
          {prestataire.aboutme ? (
            <p>{prestataire.aboutme}</p>
          ) : (
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odit
              explicabo necessitatibus totam delectus aliquid cupiditate sed
              maxime atque labore ex, a sapiente deleniti doloribus, error
              soluta animi? Quod, adipisci ipsa! Lorem ipsum dolor sit amet
              consectetur adipisicing elit. Ad ducimus esse sed minus modi! Cum
              iure, eligendi rerum unde excepturi repellat minus ex facere illo
              vero neque aliquam totam quae! Lorem ipsum dolor sit amet
              consectetur, adipisicing elit. Amet facilis, quisquam doloremque
              unde exercitationem praesentium quod nobis ab nihil velit ex.
              Quibusdam atque nisi cupiditate voluptatum beatae quia ipsam quos.
            </p>
          )}
        </div>
      </div>

      {/* section mes prestations */}
      {/* {categories.map((categorie) => {
        return (
          <div key={categorie.id}>
            <div className="p-4">
              <h2 className="text-2xl font-bold text-black">{categorie.nom}</h2>
            </div>
            <div className="services-row grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
              {prestations
                .filter((service) => service.category === categorie.nom)
                .map((filteredService) => (
                  <Service
                    key={filteredService.id}
                    categorie={categorie}
                    service={filteredService}
                  />
                ))}
            </div>
          </div>
        );
      })} */}

      <Accordion
        type="single"
        collapsible
        className="w-full"
        value={openCategory}
        onValueChange={setOpenCategory}
      >
        {categories.map((category, index) => (
          <AccordionItem key={index} value={`item-${index}`}>
            <AccordionTrigger className="text-xl">
              {category.nom}
            </AccordionTrigger>
            <AccordionContent>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                {prestations
                  .filter((service) => service.category === category.nom)
                  .map((filteredService) => (
                    <Service
                      key={filteredService.id}
                      categorie={category}
                      service={filteredService}
                    />
                  ))}
              </div>
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>

      {/* section mes produits */}
      {produits.length > 0 && (
        <div className="my-4 bg-slate-100 p-4 rounded">
          <h1 className="text-xl font-bold">Mes produits :</h1>
          <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
            {produits.map((produit) => (
              <Product key={produit.id} product={produit} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default PagePrestataire;
