import ky from "ky";
export const LogDiscord = async (message: string, name: string) => {
  const url = import.meta.env.VITE_DISCORD_WEBHOOK;
  const data = {
    content: message,
    username: name,
  };
  try {
    await ky.post(url, { json: data }); // Modifiez ici
    console.info("Message sent to Discord");
  } catch (error) {
    console.error(error);
  }
};
