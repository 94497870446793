/* eslint-disable react-hooks/exhaustive-deps */
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import {
  WhereFilterOp,
  collection,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { CheckCircle } from "lucide-react";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { useAuth } from "../contexts/AuthContext";
import { FbOrm } from "../utils/fborm";
import { Order as OrderType } from "../utils/types";

const OrdersPage = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [orders, setOrders] = useState<OrderType[] | null>(null);

  const getClient = async () => {
    if (!currentUser) return;
    const collectionToFetch = [
      {
        name: "clients",
        where: [
          {
            field: "email",
            operator: "==" as WhereFilterOp,
            value: currentUser.email,
          },
        ],
      },
    ];
    const clientDoc = await FbOrm(collectionToFetch);
    if (!clientDoc.clients) return;
    getOrders(clientDoc.clients.at(0).id);
  };

  const getOrders = async (clientId: string) => {
    const ordersCol = collection(db, "commandes");
    const q = query(ordersCol, where("clientId", "==", clientId));
    onSnapshot(q, (querySnapshot) => {
      const orders: OrderType[] = [];
      querySnapshot.forEach((doc) => {
        const data = { ...doc.data(), id: doc.id };
        orders.push(data as OrderType);
      });
      orders.sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      );
      setOrders(orders as OrderType[]);
    });
  };

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }

  useEffect(() => {
    if (!currentUser) {
      navigate("/login?redirect=orders");
    } else {
      getClient();
    }
  }, []);
  if (!orders) return <div>Loading...</div>;
  return (
    <div className="bg-white">
      <div className="py-16 sm:py-24">
        <div className="mx-auto max-w-7xl sm:px-2 lg:px-8">
          <div className="mx-auto max-w-2xl px-4 lg:max-w-4xl lg:px-0">
            <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
              Vos commandes
            </h1>
            <p className="mt-2 text-sm text-gray-500">
              Obtenir des informations sur une commande.
            </p>
          </div>
        </div>

        <div className="mt-16">
          <h2 className="sr-only">Commandes récentes</h2>
          <div className="mx-auto max-w-7xl sm:px-2 lg:px-8">
            <div className="mx-auto max-w-2xl space-y-8 sm:px-4 lg:max-w-4xl lg:px-0">
              {orders.map((order) => {
                const orderDate = new Date(order.date);
                const orderDateStr = orderDate.toLocaleDateString("fr-FR");
                const orderTotal = 0;
                order.products.reduce((acc, product) => {
                  if (!product.quantity) {
                    product.quantity = 1;
                  }
                  acc += parseFloat(product.price) * product.quantity;
                  return acc;
                }, 0);

                return (
                  <div
                    key={order.id}
                    className="border-b border-t border-gray-200 bg-white shadow-sm sm:rounded-lg sm:border"
                  >
                    <h3 className="sr-only">
                      Order placed on{" "}
                      <time dateTime={order.date}>{order.date}</time>
                    </h3>

                    <div className="flex items-center border-b border-gray-200 p-4 sm:grid sm:grid-cols-4 sm:gap-x-6 sm:p-6">
                      <dl className="grid flex-1 grid-cols-2 gap-x-6 text-sm sm:col-span-3 sm:grid-cols-3 lg:col-span-3">
                        <div>
                          <dt className="font-medium text-gray-900">
                            Numéro de commande
                          </dt>
                          <dd className="mt-1 text-gray-500 uppercase">
                            #{order.id}
                          </dd>
                        </div>
                        <div className="hidden sm:block">
                          <dt className="font-medium text-gray-900">
                            Date placed
                          </dt>
                          <dd className="mt-1 text-gray-500">
                            <time dateTime={order.date}>{orderDateStr}</time>
                          </dd>
                        </div>
                        <div>
                          <dt className="font-medium text-gray-900">
                            Total amount
                          </dt>
                          <dd className="mt-1 font-medium text-gray-900">
                            {order.total || orderTotal} €
                          </dd>
                        </div>
                      </dl>

                      <Menu
                        as="div"
                        className="relative flex justify-end lg:hidden"
                      >
                        <div className="flex items-center">
                          <Menu.Button className="-m-2 flex items-center p-2 text-gray-400 hover:text-gray-500">
                            <span className="sr-only">
                              Options pour la commande {order.number}
                            </span>
                            <EllipsisVerticalIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-bottom-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href={order.invoiceUrl}
                                    className={classNames(
                                      active
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-gray-700",
                                      "block px-4 py-2 text-sm"
                                    )}
                                  >
                                    Facture
                                  </a>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>

                      <div className="hidden lg:flex lg:items-center lg:justify-end lg:space-x-4">
                        <a
                          href={order.invoiceUrl}
                          className="flex items-center justify-center rounded-md border border-gray-300 bg-white px-2.5 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          <span>Facture</span>
                          <span className="sr-only">
                            pour la commande #{order.id}
                          </span>
                        </a>
                      </div>
                    </div>

                    {/* Products */}
                    <h4 className="sr-only">Produits</h4>
                    <ul role="list" className="divide-y divide-gray-200">
                      {order.products.map((product) => (
                        <li key={product.id} className="p-4 sm:p-6">
                          <div className="flex items-center sm:items-start">
                            <div className="h-20 w-20 flex-shrink-0 overflow-hidden rounded-lg bg-gray-200 sm:h-40 sm:w-40">
                              <img
                                src={product.images[0].url}
                                className="h-full w-full object-cover object-center"
                              />
                            </div>
                            <div className="ml-6 flex-1 text-sm">
                              <div className="font-medium text-gray-900 sm:flex sm:justify-between">
                                <h5>{product.name}</h5>
                                <p className="mt-2 sm:mt-0">
                                  {product.price} €
                                </p>
                              </div>
                              <p className="text-black mt-2">
                                x {product.quantity}
                              </p>
                              <p className="hidden text-gray-500 sm:mt-2 sm:block">
                                {product.description}
                              </p>
                            </div>
                          </div>

                          <div className="mt-6 sm:flex sm:justify-between">
                            {order.status === "livré" && (
                              <div className="flex items-center">
                                <CheckCircle
                                  className="h-5 w-5 text-green-500"
                                  aria-hidden="true"
                                />
                                {/* <p className="ml-2 text-sm font-medium text-gray-500">
                                  Livré le{" "}
                                  <time dateTime={order.deliveredDatetime}>
                                    {order.deliveredDate}
                                  </time>
                                </p> */}
                              </div>
                            )}

                            {/* <div className="mt-6 flex items-center space-x-4 divide-x divide-gray-200 border-t border-gray-200 pt-4 text-sm font-medium sm:ml-4 sm:mt-0 sm:border-none sm:pt-0">
                              <div className="flex flex-1 justify-center">
                                <a
                                  href={product.href}
                                  className="whitespace-nowrap text-indigo-600 hover:text-indigo-500"
                                >
                                  View product
                                </a>
                              </div>
                              <div className="flex flex-1 justify-center pl-4">
                                <a
                                  href="#"
                                  className="whitespace-nowrap text-indigo-600 hover:text-indigo-500"
                                >
                                  Buy again
                                </a>
                              </div>
                            </div> */}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrdersPage;
