import { OrderByDirection } from "@firebase/firestore";
import { differenceInHours, differenceInMinutes, fromUnixTime } from "date-fns";
import {
  Timestamp,
  WhereFilterOp,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { useAuth } from "../contexts/AuthContext";
import { convertTimestampToString } from "../utils/datetime";
import { FbOrm } from "../utils/fborm";
import { sendEmail } from "../utils/mailer";
import { Appointment, ServiceDuration } from "../utils/types";

const getClientAppts = async (clientEmail: string) => {
  // fonction pour récupérer les rendez-vous d'un client depuis son identifiant
  const userId = await getUserId(clientEmail);
  const COLLECTION_TO_FETCH = [
    {
      name: "appointments",
      where: [
        {
          field: "clientId",
          operator: "==" as WhereFilterOp,
          value: userId,
        },
      ],
      orderBy: [{ field: "datedebut", direction: "desc" as OrderByDirection }],
    },
  ];
  const data = await FbOrm(COLLECTION_TO_FETCH);
  return data.appointments;
};

const calculateDuration = (
  startTimestamp: Timestamp,
  endTimestamp: Timestamp
) => {
  // Convertir les timestamps en objets Date JavaScript
  const startDate = new Date(startTimestamp.seconds * 1000);
  const endDate = new Date(endTimestamp.seconds * 1000);
  // Calculer la différence en minutes
  const durationMinutes = differenceInMinutes(endDate, startDate);
  // Convertir la durée en heures et minutes
  const hours = Math.floor(durationMinutes / 60);
  const minutes = durationMinutes % 60;
  // Retourner la durée formatée, par exemple "2h 30"
  if (minutes === 0) return `0${hours}h`;
  else if (hours === 0) return `${minutes}m`;
  else return `0${hours}h${minutes}`;
};

const getUserId = async (userEmail: string) => {
  const COLLECTION_TO_FETCH = [
    {
      name: "clients",
      where: [
        {
          field: "email",
          operator: "==" as WhereFilterOp,
          value: userEmail,
        },
      ],
    },
  ];
  const data = await FbOrm(COLLECTION_TO_FETCH);
  return data.clients[0].id;
};

const formatApptForDisplay = async (appts: Appointment[]) => {
  // fonction pour formater les rendez-vous avant de les afficher

  // convertir la date de début et de fin en chaîne de caractères
  // récupérer la durée de chaque rdv
  // récupérer le prix de chaque rdv
  // utiliser Promise.all pour pouvoir utiliser await dans un map
  const formatedAppts = await Promise.all(
    appts.map(async (appt) => {
      const startDate = convertTimestampToString(appt.datedebut);
      const endDate = convertTimestampToString(appt.datefin);
      const duration = calculateDuration(appt.datedebut, appt.datefin);
      const price = await getPrestaPrice(appt.idpresta, duration);
      const data = {
        ...appt,
        startDate,
        endDate,
        duration,
        price,
      };
      return data as AppointmentDisplay;
    })
  );

  return formatedAppts;
};

const getPrestaPrice = async (prestaId: string, duration: string) => {
  const COLLECTION_TO_FETCH = [
    {
      name: "prestations",
      where: [
        {
          field: "id",
          operator: "==" as WhereFilterOp,
          value: prestaId,
        },
      ],
    },
  ];
  const data = await FbOrm(COLLECTION_TO_FETCH);

  if (data.prestations.length === 0) return "0";

  const durations = data.prestations[0].durations;

  // Rechercher la durée correspondante et retourner son prix
  const durationObj = durations.find(
    (durationObj: ServiceDuration) => durationObj.dur === duration
  );

  // Vérifie si durationObj existe, sinon renvoie "0"
  if (!durationObj) return "0";

  // Retourne toujours une chaîne de caractères
  return String(durationObj.price);
};

const isCancellable = (startTimestamp: Timestamp) => {
  const startDate = fromUnixTime(startTimestamp.seconds);
  const hoursDiff = differenceInHours(startDate, new Date());
  return hoursDiff >= 12;
};

type AppointmentDisplay = Appointment & {
  startDate: string;
  endDate: string;
  duration: string;
  price: string;
};

const MyAppts = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [reservations, setReservations] = useState<AppointmentDisplay[]>([]);

  const deleteEvent = async (event: Appointment) => {
    // Implement delete functionality here
    if (
      !event.id ||
      event.status === "cancelled" ||
      !isCancellable(event.datedebut)
    ) {
      return;
    }
    const conf = confirm("Voulez-vous vraiment annuler ce rendez-vous ?");
    if (!conf) {
      return;
    }

    let shouldContinue = true;

    // obtenir remboursement client
    if (
      event.paymentIntentId &&
      event.paymentIntentId !== "" &&
      event.paymentIntentId !== "COUPON_USED"
    ) {
      try {
        const response = await fetch(
          import.meta.env.VITE_API_URL + "/refund-payment",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ paymentIntentId: event.paymentIntentId }),
          }
        );

        if (!response.ok) {
          throw new Error("Échec du remboursement");
        }
      } catch (error) {
        alert("Échec du remboursement. Annulation annulée.");
        shouldContinue = false;
      }
    }

    const deleteDocRef = doc(db, "appointments", event.id);

    if (!shouldContinue) return;

    if (event.clientId !== "") {
      const clientDocRef = doc(db, "clients", event.clientId);
      getDoc(clientDocRef).then((client) => {
        if (!client.exists()) return;
        const emailData = {
          toEmail: client.data().email,
          templateId: "d-345e54ff75f74515a0195f7bd48df89e",
          templateData: {
            title: "Annulation de votre rendez-vous",
            subject: "Annulation de votre rendez-vous",
            text: `Bonjour ${
              client.data().firstname
            },<br>Nous vous informons que votre rendez-vous du ${convertTimestampToString(
              event.datedebut
            )} au ${convertTimestampToString(
              event.datefin
            )} a été annulé.<br>Cordialement,<br>L'équipe Tadlik.`,
          },
        };
        sendEmail(emailData);
      });
    }
    updateDoc(deleteDocRef, { status: "cancelled" });
    setReservations(reservations.filter((res) => res.id !== event.id));
  };

  useEffect(() => {
    if (!currentUser) {
      navigate("/login");
    } else {
      getClientAppts(currentUser.email || "").then((data) => {
        formatApptForDisplay(data).then((formattedData) => {
          setReservations(formattedData);
        });
      });
    }
  }, [currentUser]);

  return (
    <div className=" text-black w-10/12 mx-auto min-h-[70vh]">
      <h1 className="text-2xl font-semibold mb-4">Mes Réservations</h1>
      <div className="overflow-x-auto">
        {reservations.length > 0 ? (
          <>
            <table className="hidden md:table min-w-full border-collapse">
              <thead>
                <tr>
                  <th className="border border-gray-300 text-black text-center p-2">
                    Prestation
                  </th>
                  <th className="border border-gray-300 text-black text-center p-2">
                    Date
                  </th>
                  <th className="border border-gray-300 text-black text-center p-2">
                    Durée
                  </th>
                  <th className="border border-gray-300 text-black text-center p-2">
                    Prix
                  </th>
                  <th className="border border-gray-300 text-black text-center p-2">
                    Annuler
                  </th>
                </tr>
              </thead>
              <tbody>
                {reservations.map((res) => (
                  <tr key={res.id}>
                    <td className="border border-gray-300 p-2">
                      <p
                        className="cursor-pointer hover:text-blue-500 text-center"
                        onClick={() => navigate("/services/" + res.idpresta)}
                      >
                        {res.name}
                      </p>
                    </td>
                    <td className="border border-gray-300 p-2 text-center">
                      {res.startDate}
                    </td>
                    <td className="border border-gray-300 p-2 text-center">
                      {res.duration}
                    </td>
                    <td className="border border-gray-300 p-2 text-center">
                      {res.price} €
                    </td>
                    <td className="border border-gray-300 p-2 text-center">
                      {
                        // Vérifier si le rendez-vous est annulable
                        res.status !== "cancelled" &&
                          !isCancellable(res.datedebut) && (
                            <div className="text-red-500 text-center">
                              Non annulable
                            </div>
                          )
                      }
                      {res.status === "cancelled" && (
                        <div className="text-red-500 text-center">Annulé</div>
                      )}
                      {res.status !== "cancelled" &&
                        isCancellable(res.datedebut) && (
                          <div
                            className="btn bg-red-500 p-2 rounded text-white border-none cursor-pointer w-full"
                            onClick={() => deleteEvent(res)}
                          >
                            x
                          </div>
                        )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="md:hidden sm:grid sm:grid-cols-2 gap-4">
              {reservations.map((res) => {
                const strDate = res.startDate.split(" ");
                const strEndDate = res.endDate.split(" ");
                const startHour = strDate[1].split(":");
                const endHour = strEndDate[1].split(":");
                const strfulldate =
                  strDate[0] +
                  " " +
                  startHour[0] +
                  ":" +
                  startHour[1] +
                  " - " +
                  endHour[0] +
                  ":" +
                  endHour[1];

                return (
                  <div
                    key={res.id}
                    className="bg-white p-4 my-4 rounded-lg shadow-lg"
                  >
                    <div className="flex flex-col items-center space-y-3">
                      <p
                        className="cursor-pointer hover:text-blue-500 text-center font-bold"
                        onClick={() => navigate("/services/" + res.idpresta)}
                      >
                        {res.name}
                      </p>
                      <p className="text-sm text-gray-500">{strfulldate}</p>
                      <p>{res.duration}</p>
                      <p>{res.price} €</p>
                      <div className="flex justify-end mt-4">
                        {res.status !== "cancelled" &&
                          !isCancellable(res.datedebut) && (
                            <div className="text-white text-center font-bold p-2 bg-grey-300 rounded">
                              Non annulable
                            </div>
                          )}
                        {res.status === "cancelled" && (
                          <div className="text-white text-center font-bold p-2 bg-red-400 rounded">
                            Annulé
                          </div>
                        )}
                        {res.status !== "cancelled" &&
                          isCancellable(res.datedebut) && (
                            <div
                              className="btn bg-red-500 p-2 rounded text-white border-none cursor-pointer"
                              onClick={() => deleteEvent(res)}
                            >
                              Annuler
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <div className="text-center">Aucun rendez-vous trouvé</div>
        )}
      </div>
    </div>
  );
};

export default MyAppts;
