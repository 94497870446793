import { Tab } from "@headlessui/react";
import { collection, getDocs } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import { CartContext } from "../contexts/CartContext";
import { Product as ProductType } from "../utils/types";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const ProductPage = () => {
  const cart = useContext(CartContext);
  const id = useParams().id;
  const [product, setProduct] = useState<ProductType | null>(null);
  useEffect(() => {
    const getProduct = async () => {
      const productsCol = collection(db, "produits");
      const snapshot = await getDocs(productsCol);
      const products: ProductType[] = [];
      snapshot.docs.map((doc) => {
        products.push({ ...doc.data(), id: doc.id } as ProductType);
      });
      const newProduct = products.find((product) => product.id === id);
      if (newProduct) setProduct(newProduct);
    };
    getProduct();
  }, []);

  return (
    <div className="min-h-[70vh]">
      {product && (
        <div className="bg-white">
          <div className="mx-auto px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
            {/* Product */}
            <div className="lg:grid lg:grid-cols-7 lg:grid-rows-1 lg:gap-x-8 lg:gap-y-10 xl:gap-x-16">
              {/* Product image */}
              <div className="lg:col-span-4 lg:row-end-1">
                <div className="aspect-h-3 aspect-w-4 overflow-hidden rounded-lg bg-gray-100">
                  {/*  */}
                  <Tab.Group as="div" className="flex flex-col-reverse p-2">
                    {/* Image selector */}
                    <div className="mx-auto mt-6 w-full max-w-2xl sm:block lg:max-w-none">
                      <Tab.List className="grid grid-cols-4 gap-3 p-4">
                        {product.images &&
                          product.images.map((image) => (
                            <Tab
                              key={image.id}
                              className="relative flex h-20 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                            >
                              {({ selected }) => (
                                <>
                                  <span className="sr-only">{image.name}</span>
                                  <span className="absolute inset-0 overflow-hidden rounded-md">
                                    {image.type == "image" ? (
                                      <img
                                        src={image.url}
                                        alt=""
                                        className="h-full w-full object-cover object-center"
                                      />
                                    ) : (
                                      <video
                                        src={image.url}
                                        loop={false}
                                        className="h-full w-full object-cover object-center"
                                      />
                                    )}
                                  </span>
                                  <span
                                    className={classNames(
                                      selected
                                        ? "ring-indigo-500"
                                        : "ring-transparent",
                                      "pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2"
                                    )}
                                    aria-hidden="true"
                                  />
                                </>
                              )}
                            </Tab>
                          ))}
                      </Tab.List>
                    </div>

                    <Tab.Panels className="aspect-h-1 aspect-w-1 w-full">
                      {product.images &&
                        product.images.map((image, index) => (
                          <Tab.Panel key={index}>
                            {image.type == "image" ? (
                              <img
                                src={image.url}
                                alt={image.alt}
                                className="h-full w-full object-cover object-center sm:rounded-lg"
                              />
                            ) : (
                              <video
                                src={image.url}
                                controls={true}
                                loop={false}
                                className="h-full w-full object-cover object-center"
                              />
                            )}
                          </Tab.Panel>
                        ))}
                    </Tab.Panels>
                  </Tab.Group>
                </div>
              </div>

              {/* Product details */}
              <div className="mx-auto mt-14 max-w-2xl sm:mt-16 lg:col-span-3 lg:row-span-2 lg:row-end-2 lg:mt-0 lg:max-w-none">
                <div className="flex flex-col-reverse">
                  <div className="mt-4">
                    <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                      {product.name}
                    </h1>
                  </div>

                  {/* {reviews && (
                  <div>
                    <h3 className="sr-only">Reviews</h3>
                    {reviews?.rating && (
                      <div className="flex items-center">
                        {[0, 1, 2, 3, 4].map((rating) => (
                          <StarIcon
                            key={rating}
                            className={classNames(
                              reviews?.average > rating
                                ? "text-yellow-400"
                                : "text-gray-300",
                              "h-5 w-5 flex-shrink-0"
                            )}
                            aria-hidden="true"
                          />
                        ))}
                      </div>
                    )}
                    <p className="sr-only">{reviews?.average} sur 5 étoiles</p>
                  </div>
                )} */}
                </div>

                <p className="mt-6 text-gray-500">{product.description}</p>

                <div className="mt-8">
                  <div className="flex items-center justify-between">
                    <h2 className="text-sm font-medium text-gray-900">Durée</h2>
                  </div>
                </div>

                <div className="mt-8">
                  <div className="flex items-center justify-between">
                    <h2 className="text-sm text-gray-900">
                      Prix :{" "}
                      <span className="font-medium">{product.price} €</span>
                    </h2>
                  </div>
                </div>

                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4">
                  <button
                    onClick={() => cart.addOneToCart(product)}
                    className="flex w-full items-center justify-center rounded-md border border-transparent bg-black px-8 py-3 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                  >
                    Ajouter au panier
                  </button>
                </div>
              </div>

              <div className="mx-auto mt-16 w-full max-w-2xl lg:col-span-7 lg:mt-0 lg:max-w-none">
                {/* <Tab.Group as="div">
                <div className="border-b border-gray-200">
                  <Tab.List className="-mb-px flex space-x-8">
                    <Tab
                      className={({ selected }) =>
                        classNames(
                          selected
                            ? "border-indigo-600 text-indigo-600"
                            : "border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800",
                          "whitespace-nowrap border-b-2 py-6 text-sm font-medium"
                        )
                      }
                    >
                      Customer Reviews
                    </Tab>
                    <Tab
                      className={({ selected }) =>
                        classNames(
                          selected
                            ? "border-indigo-600 text-indigo-600"
                            : "border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800",
                          "whitespace-nowrap border-b-2 py-6 text-sm font-medium"
                        )
                      }
                    >
                      FAQ
                    </Tab>
                  </Tab.List>
                </div>
                <Tab.Panels as={Fragment}>
                  {/*  <Tab.Panel className="-mb-10">
                    <h3 className="sr-only">Customer Reviews</h3>
  
                    {reviews?.featured?.map((review, reviewIdx) => (
                      <div
                        key={review.id}
                        className="flex space-x-4 text-sm text-gray-500"
                      >
                        <div className="flex-none py-10">
                          <img
                            src={review.avatarSrc}
                            alt=""
                            className="h-10 w-10 rounded-full bg-gray-100"
                          />
                        </div>
                        <div
                          className={classNames(
                            reviewIdx === 0 ? "" : "border-t border-gray-200",
                            "py-10"
                          )}
                        >
                          <h3 className="font-medium text-gray-900">
                            {review.author}
                          </h3>
                          <p>
                            <time dateTime={review.datetime}>{review.date}</time>
                          </p>
  
                          <div className="mt-4 flex items-center">
                            {[0, 1, 2, 3, 4].map((rating) => (
                              <StarIcon
                                key={rating}
                                className={classNames(
                                  review.rating > rating
                                    ? "text-yellow-400"
                                    : "text-gray-300",
                                  "h-5 w-5 flex-shrink-0"
                                )}
                                aria-hidden="true"
                              />
                            ))}
                          </div>
                          <p className="sr-only">
                            {review.rating} out of 5 stars
                          </p>
  
                          <div
                            className="prose prose-sm mt-4 max-w-none text-gray-500"
                            dangerouslySetInnerHTML={{ __html: review.content }}
                          />
                        </div>
                      </div>
                    ))}
                  </Tab.Panel>
  
                  <Tab.Panel className="text-sm text-gray-500">
                    <h3 className="sr-only">Frequently Asked Questions</h3>
  
                    <dl>
                      {faqs.map((faq) => (
                        <Fragment key={faq.question}>
                          <dt className="mt-10 font-medium text-gray-900">
                            {faq.question}
                          </dt>
                          <dd className="prose prose-sm mt-2 max-w-none text-gray-500">
                            <p>{faq.answer}</p>
                          </dd>
                        </Fragment>
                      ))}
                    </dl>
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductPage;
