import { Disclosure } from "@headlessui/react";
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { db } from "../../firebase";
import { useAuth } from "../contexts/AuthContext";
import { CartContext } from "../contexts/CartContext";
import { Client } from "../utils/types";

const LivraisonPage = () => {
  const { currentUser } = useAuth();
  const cart = useContext(CartContext);
  const [total, setTotal] = useState(0);
  const [totalWithDiscount, setTotalWithDiscount] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [taxes, setTaxes] = useState(0);
  const [userData, setUserData] = useState<Client | null>(null);
  const [discountCode, setDiscountCode] = useState("");
  const [discount, setDiscount] = useState(false);
  const [reducPromo, setReducPromo] = useState(0);
  const [reducAmount, setReducAmount] = useState(0);
  const [error, setError] = useState("");
  const [livraisonInfo, setLivraisonInfo] = useState({
    id: "",
    nom: "",
    prenom: "",
    email: "",
    adresse: "",
    ville: "",
    codePostal: "",
    phone: "",
    // Ajoutez d'autres champs si nécessaire
  });

  const applyDiscount = async (code: string) => {
    // Assuming you have a function to get the discount percentage from the code
    const q = query(collection(db, "codesPromos"), where("code", "==", code));
    const snapshot = await getDocs(q);

    if (snapshot.docs.length > 0) {
      const promo = snapshot.docs[0].data();
      setDiscount(true);
      const discountAmount = (total * (promo.reduction / 100)).toFixed(2);
      setReducPromo(promo.reduction);
      setReducAmount(parseFloat(discountAmount));
      const newTotalWithDiscount = (total - parseFloat(discountAmount)).toFixed(
        2
      );
      setTotalWithDiscount(parseFloat(newTotalWithDiscount));
    } else {
      alert("Code Promo invalide");
      setDiscount(false);
      setReducPromo(0);
      setTotalWithDiscount(total);
      setReducAmount(0);
      calculateTotals();
    }
  };

  const calculateTotals = async () => {
    const newTotal = parseFloat(cart.getTotalCost());
    const newSubTotal = (newTotal * 0.8).toFixed(2);
    const newTaxes = (newTotal * 0.2).toFixed(2);
    if (discount) {
      const newReducAmount = newTotal * (reducPromo / 100);
      const newTotalWithDiscount = (newTotal - newReducAmount).toFixed(2);
      setTotalWithDiscount(parseFloat(newTotalWithDiscount));
      if (newReducAmount === 0) {
        setDiscount(false);
      }
      setReducAmount(newReducAmount);
    } else {
      setTotalWithDiscount(newTotal);
      setDiscount(false);
    }
    setTotal(newTotal);
    setSubTotal(parseFloat(newSubTotal));
    setTaxes(parseFloat(newTaxes));
  };

  useEffect(() => {
    calculateTotals();
  }, [cart.items]);

  useEffect(() => {
    const getUserData = async () => {
      if (!currentUser) {
        return;
      }
      const q = query(
        collection(db, "clients"),
        where("email", "==", currentUser.email)
      );
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        setUserData({ ...doc.data(), id: doc.id } as Client);
        setLivraisonInfo({
          id: doc.id,
          nom: doc.data().lastname || "",
          prenom: doc.data().firstname || "",
          email: doc.data().email || "",
          adresse: doc.data().adress || "",
          ville: doc.data().city || "",
          codePostal: doc.data().zipcode || "",
          phone: doc.data().phone || "",
        });
      });
    };
    getUserData();
    if (!currentUser) {
      window.location.replace("/login");
    }
    if (cart.items.length === 0) {
      const cartToParse = localStorage.getItem("order");
      if (cartToParse) {
        const savedCart = JSON.parse(cartToParse);
        cart.setItems(savedCart.products);
      }
    }
  }, []);

  const handleRemoveFromCart = (productId: string) => {
    cart.removeOneFromCart(productId);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLivraisonInfo({ ...livraisonInfo, [name]: value });
  };

  const addNewHistory = (sessionId: string, sessionUrl: string) => {
    if (!userData) return;
    // ajouter une nouvelle commande dans la base de données
    const newHistory = {
      id: "",
      clientId: userData.id,
      sessionId: sessionId,
      date: new Date().toISOString(),
      products: cart.items,
      LivraisonInfos: livraisonInfo,
      status: "en cours",
      total: total,
      subtotal: subTotal,
    };
    localStorage.setItem("order", JSON.stringify(newHistory));
    addDoc(collection(db, "commandes"), newHistory)
      .then((docRef) => {
        newHistory.id = docRef.id;
        localStorage.setItem("order", JSON.stringify(newHistory));
        window.location.replace(sessionUrl);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  };
  const getStripeSessionUrl = () => {
    // get stripe session url

    if (cart.items.length > 0) {
      const productsToSend = cart.items.map((item) => {
        return {
          name: item.name,
          quantity: item.quantity,
          price: item.price,
        };
      });
      const totalToSend = discount ? totalWithDiscount : total;

      fetch(import.meta.env.VITE_API_URL + "/create-checkout-session", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ products: productsToSend, total: totalToSend }),
      })
        .then((res) => res.json())
        .then((session) => {
          addNewHistory(session.id, session.url);
          // rediriger vers la page de paiement
        });
    } else {
      alert("Votre panier est vide");
    }
  };

  const checkCodePromo = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    applyDiscount(discountCode);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError("");
    // Traitez les informations de livraison ici (ex: passer à la page de paiement)
    if (
      !livraisonInfo.email ||
      !livraisonInfo.nom ||
      !livraisonInfo.prenom ||
      !livraisonInfo.adresse ||
      !livraisonInfo.ville ||
      !livraisonInfo.codePostal ||
      !livraisonInfo.phone
    ) {
      setError("Veuillez remplir tous les champs !");
      return;
    }
    getStripeSessionUrl();
  };

  return (
    <main className="lg:flex lg:min-h-full lg:flex-row-reverse lg:overflow-hidden">
      <h1 className="sr-only">Paiement</h1>

      {/* Mobile order summary */}
      <section
        aria-labelledby="order-heading"
        className="bg-gray-50 px-4 py-6 sm:px-6 lg:hidden"
      >
        <Disclosure as="div" className="mx-auto max-w-lg">
          {({ open }) => (
            <>
              <div className="flex items-center justify-between">
                <h2
                  id="order-heading"
                  className="text-lg font-medium text-gray-900"
                >
                  Votre Commande
                </h2>
                <Disclosure.Button className="font-medium text-indigo-600 hover:text-indigo-500">
                  {open ? (
                    <span>Cacher le résumé</span>
                  ) : (
                    <span>Afficher le résumé</span>
                  )}
                </Disclosure.Button>
              </div>

              <Disclosure.Panel>
                <ul
                  role="list"
                  className="divide-y divide-gray-200 border-b border-gray-200"
                >
                  {cart.items.map((product) => (
                    <li key={product.id} className="flex space-x-6 py-6">
                      <img
                        src={product.images[0].url}
                        className="h-40 w-40 flex-none rounded-md bg-gray-200 object-cover object-center"
                      />
                      <div className="flex flex-col justify-between space-y-4">
                        <div className="space-y-1 text-sm font-medium">
                          <h3 className="text-gray-900">{product.name}</h3>
                          <p className="text-gray-900">{product.price} €</p>
                          <p className="text-gray-900">
                            qté {product.quantity}
                          </p>
                        </div>
                        <div className="flex space-x-4">
                          <button
                            type="button"
                            onClick={() => {
                              if (!product.id) return;
                              handleRemoveFromCart(product.id);
                            }}
                            className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                          >
                            Supprimer
                          </button>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>

                <form className="mt-10">
                  <label
                    htmlFor="discount-code-mobile"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Code de Réduction
                  </label>
                  <div className="mt-1 flex space-x-4">
                    <input
                      type="text"
                      id="discount-code-mobile"
                      name="discount-code-mobile"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                    <button
                      type="submit"
                      className="rounded-md bg-gray-200 px-4 text-sm font-medium text-gray-600 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                    >
                      Appliquer
                    </button>
                  </div>
                </form>

                <dl className="mt-10 space-y-6 text-sm font-medium text-gray-500">
                  <div className="flex justify-between">
                    <dt>Sous total</dt>
                    <dd className="text-gray-900">{subTotal} €</dd>
                  </div>
                  {discount && (
                    <div className="flex justify-between">
                      <dt className="flex">
                        Promotion :
                        <span className="ml-2 rounded-full bg-gray-200 px-2 py-0.5 text-xs tracking-wide text-gray-600">
                          {discountCode}
                        </span>
                      </dt>
                      <dd className="text-gray-900">-{reducAmount} €</dd>
                    </div>
                  )}
                  {taxes && (
                    <div className="flex justify-between">
                      <dt>Taxes</dt>
                      <dd className="text-gray-900">{taxes} €</dd>
                    </div>
                  )}
                </dl>
              </Disclosure.Panel>

              <p className="mt-6 flex items-center justify-between border-t border-gray-200 pt-6 text-sm font-medium text-gray-900">
                <span className="text-base">Total</span>
                <span className="text-base">
                  {discount ? totalWithDiscount : total} €
                </span>
              </p>
            </>
          )}
        </Disclosure>
      </section>

      {/* Order summary */}
      <section
        aria-labelledby="summary-heading"
        className="hidden w-full max-w-md flex-col bg-gray-50 lg:flex"
      >
        <h2 id="summary-heading" className="sr-only">
          Résumé de la commande
        </h2>

        <ul
          role="list"
          className="flex-auto divide-y divide-gray-200 overflow-y-auto px-6"
        >
          {cart.items.map((product) => (
            <li key={product.id} className="flex space-x-6 py-6">
              <img
                src={product.images[0].url}
                className="h-40 w-40 flex-none rounded-md bg-gray-200 object-cover object-center"
              />
              <div className="flex flex-col justify-between space-y-4">
                <div className="space-y-1 text-sm font-medium">
                  <h3 className="text-gray-900">{product.name}</h3>
                  <p className="text-gray-900">{product.price} €</p>
                  <p className="text-gray-900">qté {product.quantity}</p>
                </div>
                <div className="flex space-x-4">
                  <button
                    type="button"
                    onClick={() => handleRemoveFromCart(product.id || "")}
                    className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Supprimer
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>

        <div className="sticky bottom-0 flex-none border-t border-gray-200 bg-gray-50 p-6">
          <form>
            <label
              htmlFor="discount-code"
              className="block text-sm font-medium text-gray-700"
            >
              Code Promo
            </label>
            <div className="mt-1 flex space-x-4">
              <input
                type="text"
                id="discount-code"
                name="discount-code"
                onChange={(e) => setDiscountCode(e.target.value)}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
              <button
                type="submit"
                onClick={(e) => checkCodePromo(e)}
                className="rounded-md bg-gray-200 px-4 text-sm font-medium text-gray-600 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
              >
                Appliquer
              </button>
            </div>
          </form>

          <dl className="mt-10 space-y-6 text-sm font-medium text-gray-500">
            <div className="flex justify-between">
              <dt>Sous Total</dt>
              <dd className="text-gray-900">{subTotal} €</dd>
            </div>
            {discount && (
              <div className="flex justify-between">
                <dt className="flex">
                  Promotion :
                  <span className="ml-2 rounded-full bg-gray-200 px-2 py-0.5 text-xs tracking-wide text-gray-600">
                    {discountCode}
                  </span>
                </dt>
                <dd className="text-gray-900">-{reducAmount} €</dd>
              </div>
            )}
            {taxes && (
              <div className="flex justify-between">
                <dt>Taxes</dt>
                <dd className="text-gray-900">{taxes} €</dd>
              </div>
            )}

            <div className="flex items-center justify-between border-t border-gray-200 pt-6 text-gray-900">
              <dt className="text-base">Total</dt>
              <dd className="text-base">
                {discount ? totalWithDiscount : total} €
              </dd>
            </div>
          </dl>
        </div>
      </section>

      {/* Checkout form */}
      <section
        aria-labelledby="payment-heading"
        className="flex-auto overflow-y-auto px-4 pb-16 pt-12 sm:px-6 sm:pt-16 lg:px-8 lg:pb-24 lg:pt-0"
      >
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 my-4 rounded relative">
            {error}
          </div>
        )}
        <div className="mx-auto max-w-lg">
          <form className="mt-6" onSubmit={handleSubmit} autoComplete="off">
            <div className="grid grid-cols-12 gap-x-4 gap-y-6">
              <div className="col-span-full">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email
                </label>
                <div className="mt-1">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    autoComplete="off"
                    defaultValue={livraisonInfo.email}
                    onChange={handleChange}
                    className="block w-full rounded-md border-gray-300 text-black shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="nom"
                  className="block text-sm font-medium text-gray-700"
                >
                  Nom
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    id="nom"
                    name="nom"
                    defaultValue={livraisonInfo.nom}
                    autoComplete="off"
                    onChange={handleChange}
                    className="block w-full rounded-md border-gray-300 shadow-sm text-black focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="prenom"
                  className="block text-sm font-medium text-gray-700"
                >
                  Prénom
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    id="prenom"
                    name="prenom"
                    defaultValue={livraisonInfo.prenom}
                    onChange={handleChange}
                    autoComplete="off"
                    className="block w-full rounded-md border-gray-300 text-black shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="address"
                  className="block text-sm font-medium text-gray-700"
                >
                  Adresse
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    id="adresse"
                    name="adresse"
                    defaultValue={livraisonInfo.adresse}
                    onChange={handleChange}
                    autoComplete="off"
                    className="block w-full rounded-md border-gray-300 text-black shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="col-span-full sm:col-span-6">
                <label
                  htmlFor="city"
                  className="block text-sm font-medium text-gray-700"
                >
                  Ville
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    id="ville"
                    name="ville"
                    defaultValue={livraisonInfo.ville}
                    onChange={handleChange}
                    autoComplete="off"
                    className="block w-full rounded-md border-gray-300 shadow-sm text-black focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="col-span-full sm:col-span-6">
                <label
                  htmlFor="postal-code"
                  className="block text-sm font-medium text-gray-700"
                >
                  Code postal
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    id="codePostal"
                    name="codePostal"
                    defaultValue={livraisonInfo.codePostal}
                    onChange={handleChange}
                    autoComplete="off"
                    className="block w-full rounded-md border-gray-300 shadow-sm text-black focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-700"
                >
                  Téléphone
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    autoComplete="off"
                    defaultValue={livraisonInfo.phone}
                    onChange={handleChange}
                    className="block w-full rounded-md border-gray-300 shadow-sm text-black focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
            </div>

            {/* <div className="mt-6 flex space-x-2">
              <div className="flex h-5 items-center">
                <input
                  id="same-as-shipping"
                  name="same-as-shipping"
                  type="checkbox"
                  defaultChecked
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
              </div>
              <label
                htmlFor="same-as-shipping"
                className="text-sm font-medium text-gray-900"
              >
                Adresse de facturation identique à l'adresse de livraison
              </label>
            </div> */}

            <button
              type="submit"
              className="mt-6 w-full rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Payer {discount ? totalWithDiscount : total} €
            </button>
          </form>
        </div>
      </section>
    </main>
  );
};

export default LivraisonPage;
