import { LogDiscord } from "../../utils/LogDiscord";
import {
  addDurationToDate,
  convertToFirebaseTimestamp,
} from "../../utils/datetime";

export const formatApptData = (
  date: string,
  creneau: string,
  duration: string,
  nom: string,
  prenom: string,
  email: string
) => {
  const resDateDebut = convertToFirebaseTimestamp(date, creneau);
  if (!resDateDebut) {
    LogDiscord("resDateDebut is undefined", "testNewAlgorithm.ts");
    throw new Error("resDateDebut is undefined");
  }
  const resDateFin = addDurationToDate(resDateDebut.toDate(), duration);
  let clientNameOrEmail = "";

  if (!nom && !prenom) {
    clientNameOrEmail = email;
  } else {
    clientNameOrEmail = nom + " " + prenom;
  }

  const description = ` pour ${clientNameOrEmail} le ${date} à ${creneau}`;

  return { resDateDebut, resDateFin, description };
};
