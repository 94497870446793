import { PropsWithChildren, createContext, useState } from "react";
import { Product } from "../utils/types";
export const CartContext = createContext<CartContextType>({
  items: [],
  getproductQuantity: () => 0,
  addOneToCart: () => {},
  removeOneFromCart: () => {},
  deleteFromCart: () => {},
  getTotalCost: () => "",
  setItems: () => {},
});

type CartContextType = {
  items: CartProduct[];
  getproductQuantity: (id: string) => number;
  addOneToCart: (product: CartProduct) => void;
  removeOneFromCart: (id: CartProduct["id"]) => void;
  deleteFromCart: (id: CartProduct["id"]) => void;
  getTotalCost: () => string;
  setItems: (items: CartProduct[]) => void;
};

type CartProduct = Product;

export const CartProvider = (props: PropsWithChildren) => {
  const [cart, setCart] = useState<CartProduct[]>([]);

  const getproductQuantity = (id: string) => {
    const quantity = cart.find((item) => item.id === id)?.quantity;
    return quantity ? quantity : 0;
  };

  const addOneToCart = (product: CartProduct) => {
    if (!product.id) return;
    const quantity = getproductQuantity(product.id);

    if (quantity === 0) {
      setCart([...cart, { ...product, quantity: 1 }]);
    } else {
      const newCart = [...cart];
      const item = newCart.find((item) => item.id === product.id);
      if (item && item.quantity) {
        item.quantity++;
        setCart(newCart);
      } else if (item) {
        item.quantity = 1;
        setCart(newCart);
      }
    }
  };

  const deleteFromCart = (id: Product["id"]) => {
    const newCart = cart.filter((item) => item.id !== id);
    setCart(newCart);
  };

  const removeOneFromCart = (id: Product["id"]) => {
    if (!id) return;
    const quantity = getproductQuantity(id);
    if (quantity === 1) {
      deleteFromCart(id);
    } else {
      const newCart = [...cart];
      const item = newCart.find((item) => item.id === id);
      if (item) {
        item.quantity ? item.quantity-- : (item.quantity = 0);
        setCart(newCart);
      }
    }
  };

  const getTotalCost = () => {
    let totalCost = 0;
    cart.forEach((item) => {
      if (!item.quantity) item.quantity = 1;
      const priceOfItem = parseFloat(item.price) * item.quantity;
      totalCost += priceOfItem;
    });

    // Convertir le total en chaîne de caractères avec deux chiffres après la virgule
    return totalCost.toFixed(2);
  };

  const setItems = (items: CartProduct[]) => setCart(items);

  const contextValue: CartContextType = {
    items: cart,
    getproductQuantity,
    addOneToCart,
    removeOneFromCart,
    deleteFromCart,
    getTotalCost,
    setItems,
  };

  return (
    <CartContext.Provider value={contextValue}>
      {props.children}
    </CartContext.Provider>
  );
};

export default CartProvider;
