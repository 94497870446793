import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { CircleUserRound, ShoppingCart } from "lucide-react";
import { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { useAuth } from "../contexts/AuthContext";
import { CartContext } from "../contexts/CartContext";
import { UserType } from "../utils/types";
import Bandeau from "./Bandeau";
import ModalCart from "./ModalCart";

type AdminType = {
  password?: string;
} & UserType;
const NavBar = () => {
  const cart = useContext(CartContext);
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname.slice(1);
  const { currentUser, logout } = useAuth();
  const [show, setShow] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [people, setPeople] = useState<UserType[]>([]);
  const ref = useRef<HTMLDivElement>(null);
  const handleShow = () => {
    setShow(!show);
  };

  useEffect(() => {
    // récupérer les liens des prestataires
    const getpeoples = () => {
      const adminsRef = collection(db, "admins");
      const q = query(adminsRef, orderBy("rang"));
      const peoples: UserType[] = [];
      getDocs(q)
        .then((snapshot) => {
          snapshot.docs.map((doc) => {
            const admin = { ...doc.data(), id: doc.id } as AdminType;
            delete admin.password;
            if (admin.job !== "développeur" && admin.isEnabled) {
              peoples.push(admin);
            }
          });
        })
        .then(() => {
          setPeople(peoples);
        });
    };
    getpeoples();
  }, []);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setShowUserMenu(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const productsCount = cart.items.reduce(
    (sum, item) => sum + (item.quantity ?? 1),
    0
  );

  return (
    <>
      <div className="bg-white z-20 sticky top-0">
        <div className="navbar bg-white text-black md:w-10/12 md:mx-auto">
          <div className="navbar-start">
            <div className="dropdown">
              <label tabIndex={0} className="btn btn-ghost xl:hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h8m-8 6h16"
                  />
                </svg>
              </label>
              <ul
                tabIndex={0}
                className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-white rounded-box w-56"
              >
                <li className="text-sm font-semibold leading-6 text-gray-900">
                  <Link to={"/"}>Accueil</Link>
                </li>
                <li className="text-sm font-semibold leading-6 text-gray-900">
                  <Link to={"/contact"}>Contact</Link>
                </li>
                {people.map((item) => (
                  <li
                    key={item.name}
                    className="text-sm font-semibold leading-6 text-gray-900"
                  >
                    <Link to={`/p/${item.id}`}>{item.name}</Link>
                  </li>
                ))}
                {currentUser ? (
                  <>
                    <li className="text-sm font-semibold leading-6 text-gray-900">
                      <Link to={"/profil"}>Mon Compte</Link>
                    </li>
                    <li className="text-sm font-semibold leading-6 text-gray-900">
                      <Link to={"/myappt"}>Mes Réservations</Link>
                    </li>
                    <li className="text-sm font-semibold leading-6 text-gray-900">
                      <Link to={"/orders"}>Mes Commandes</Link>
                    </li>
                    <li
                      className="btn btn-error text-white"
                      onClick={() => {
                        logout();
                        navigate("/");
                      }}
                    >
                      Se Déconnecter
                    </li>
                  </>
                ) : (
                  <li className="text-sm font-semibold leading-6 text-gray-900">
                    <Link to={`/login?redirect=${currentPath}`}>
                      Se Connecter
                    </Link>
                  </li>
                )}
              </ul>
            </div>
            <Link to={"/"} className="btn btn-ghost text-2xl">
              TADLIK
            </Link>
          </div>
          <div className="navbar-center hidden xl:flex">
            <ul className="menu menu-horizontal px-1">
              <li className="text-sm font-semibold leading-6 text-gray-900">
                <Link to={"/"}>Accueil</Link>
              </li>

              {people.map((item) => (
                <li
                  key={item.name}
                  className="text-sm font-semibold leading-6 text-gray-900"
                >
                  <Link to={`/p/${item.id}`}>{item.name}</Link>
                </li>
              ))}
              <li className="text-sm font-semibold leading-6 text-gray-900">
                <Link to={"/contact"}>Contact</Link>
              </li>
            </ul>
          </div>
          <div className="navbar-end space-x-8 md:space-x-4">
            {/* Ajouter panier, mettre un if pour ne pas l'afficher si panier vide.*/}
            <div
              className="indicator md:px-4 md:mx-8 cursor-pointer"
              onClick={handleShow}
            >
              {productsCount > 0 && (
                <span className="indicator-item badge badge-error text-white font bold">
                  {/* nb produit dans panier */}
                  {productsCount}
                </span>
              )}
              <div className="">
                <ShoppingCart />
              </div>
            </div>
            <Link to={"/services"} className="btn bg-black text-white">
              Réserver / Offrir
            </Link>
            <div
              ref={ref}
              onClick={() => setShowUserMenu(!showUserMenu)}
              className="hidden md:block"
            >
              <CircleUserRound />
              {showUserMenu && (
                <div className="absolute bg-white top-20 right-8 p-4 flex flex-col gap-4 rounded-lg z-50 shadow-xl">
                  {currentUser ? (
                    <>
                      <Link
                        to={"/orders"}
                        className="hover:link-hover text-sm font-semibold leading-6 text-gray-900"
                      >
                        Mes Commandes
                      </Link>
                      <Link
                        to={"/myappt"}
                        className="hover:link-hover text-sm font-semibold leading-6 text-gray-900"
                      >
                        Mes Réservations
                      </Link>
                      <Link
                        to={"/profil"}
                        className="hover:link-hover text-sm font-semibold leading-6 text-gray-900"
                      >
                        Mon Compte
                      </Link>
                      <div
                        className="btn btn-error text-white"
                        onClick={() => {
                          logout();
                          navigate("/");
                        }}
                      >
                        Se Deconnecter
                      </div>
                    </>
                  ) : (
                    <Link
                      to={`/login?redirect=${currentPath}`}
                      className="btn bg-black text-white"
                    >
                      Se Connecter
                    </Link>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {show && <ModalCart handleShow={handleShow} />}
      <Bandeau />
    </>
  );
};

export default NavBar;
