"use client";

import Service from "@/components/Service";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { db } from "../../firebase";
import { CategoryType, Service as ServiceType, UserType } from "../utils/types";

type AdminType = {
  password?: string;
} & UserType;

export default function MassageShopServices() {
  const [openCategory, setOpenCategory] = useState<string | undefined>(
    undefined
  );

  const [selectedPerson, setSelectedPerson] = useState<string | null>(null);
  const [people, setPeople] = useState<AdminType[]>([]);
  const [categories, setCategories] = useState<CategoryType[]>([]);
  const [services, setServices] = useState<ServiceType[]>([]);
  const servicesRef = useRef(null);

  const selectedPersonStyle = "bg-gray-200";

  const getServices = (id: string) => {
    // ref de la collection services
    const servicesCol = collection(db, "prestations");
    const categoriesCol = collection(db, "categories");

    // requête pour récupérer les catégories de la personne sélectionnée

    const qcategories = query(
      categoriesCol,
      where("id_prestataire", "==", id),
      where("isEnabled", "==", true),
      orderBy("rang")
    );
    getDocs(qcategories).then((snapshot) => {
      const categories: CategoryType[] = [];
      snapshot.docs.map((doc) => {
        categories.push({ ...doc.data(), id: doc.id } as CategoryType);
      });
      setCategories(categories as CategoryType[]);
    });

    // requête pour récupérer les services de la personne sélectionnée
    const q = query(servicesCol, where("id_prestataire", "==", id));
    getDocs(q)
      .then((snapshot) => {
        const services: ServiceType[] = [];
        snapshot.docs.map((doc) => {
          services.push({ ...doc.data(), id: doc.id } as ServiceType);
        });
        setServices(services);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const selectPerson = (personId: string) => {
    setSelectedPerson(personId);
    getServices(personId);
    const isMobile = window.matchMedia("(max-width: 640px)").matches;
    if (servicesRef.current && !isMobile) {
      (servicesRef.current as HTMLElement).scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const getpeoples = () => {
    setPeople([]);
    const adminsRef = collection(db, "admins");
    const q = query(adminsRef, orderBy("rang"));
    const peoples: AdminType[] = [];
    getDocs(q)
      .then((snapshot) => {
        snapshot.docs.map((doc) => {
          const admin = { ...doc.data(), id: doc.id } as AdminType;
          delete admin.password;
          if (admin.job !== "développeur" && admin.isEnabled) {
            peoples.push(admin as AdminType);
          }
        });
      })
      .then(() => {
        setPeople(peoples);
        setSelectedPerson(peoples[0].id);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    getpeoples();
  }, []);

  useEffect(() => {
    if (selectedPerson) {
      getServices(selectedPerson);
    }
  }, [selectedPerson]);

  return (
    <div className="w-full max-w-6xl mx-auto p-6 min-h-[70vh]">
      {/* Teams */}

      <div className="bg-white pt-3 pb-6 md:pb-12 sm:pb-12">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Notre équipe
            </h2>
          </div>
          <ul
            role="list"
            className="mx-auto mt-6 md:mt-20 grid max-w-2xl grid-cols-2  gap-x-8  gap-y-4 md:gap-y-16 text-center sm:grid-cols-3 md:grid-cols-4 lg:mx-0 lg:max-w-none lg:grid-cols-5 xl:grid-cols-6"
          >
            {people.map((person) => (
              <li
                key={person.id}
                className={
                  selectedPerson == person.id
                    ? `${selectedPersonStyle} p-2 rounded-md hover:shadow-md transition duration-300 ease-in-out`
                    : "p-2 rounded-md hover:cursor-pointer border border-gray-200 hover:shadow-md transition duration-300 ease-in-out"
                }
                onClick={() => selectPerson(person.id)}
              >
                {person.imageUrl !== "" ? (
                  <img
                    className="h-12 w-12 md:h-24 md:w-24 mx-auto rounded-full self-center"
                    src={person.imageUrl}
                    alt=""
                  />
                ) : (
                  <div className="mx-auto h-12 w-12 md:h-24 md:w-24 rounded-full bg-gray-300 flex items-center justify-center">
                    {person.name && (
                      <p className="text-3xl">{person.name[0]}</p>
                    )}
                  </div>
                )}

                <div>
                  <h3 className="mt-2 md:mt-6 text-sm md:text-base font-semibold leading-7 tracking-tight text-gray-900">
                    {person.name}
                  </h3>
                  <p className="text-xs md:text-sm leading-6 text-gray-600">
                    {person.job}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {/* EndTeams */}
      <div ref={servicesRef}>
        <Accordion
          type="single"
          collapsible
          className="w-full"
          value={openCategory}
          onValueChange={setOpenCategory}
        >
          {categories.map((category, index) => {
            return (
              <AccordionItem key={index} value={`item-${index}`}>
                <AccordionTrigger className="text-xl">
                  {category.nom}
                </AccordionTrigger>
                <AccordionContent>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {services
                      .filter((service) => service.category === category.nom)
                      .map((filteredService) => (
                        <Service
                          key={filteredService.id}
                          categorie={category}
                          service={filteredService}
                        />
                      ))}
                  </div>
                </AccordionContent>
              </AccordionItem>
            );
          })}
        </Accordion>
      </div>
    </div>
  );
}
