type Props = {
  currentStep: number;
  setNexStep: (nextStep: number) => void;
};

const BottomBtns = ({ currentStep, setNexStep }: Props) => {
  return (
    <div className="actions sticky md:relative bottom-0 bg-white">
      <div className="w-10/12 md:w-8/12 lg:w-6/12 mx-auto flex justify-between pt-4">
        {currentStep > 1 && (
          <button
            onClick={() => {
              setNexStep(currentStep - 1);
            }}
            className="btn bg-black text-white hover:bg-slate-800 mb-4 cursor-pointer"
          >
            Précédent
          </button>
        )}
        {currentStep === 1 && (
          <button
            onClick={() => {
              setNexStep(currentStep - 1);
            }}
            className="btn bg-black text-white hover:bg-slate-800 mb-4 cursor-pointer opacity-5"
          >
            Précédent
          </button>
        )}
        <button
          onClick={() => {
            setNexStep(currentStep + 1);
          }}
          className="btn bg-black text-white hover:bg-slate-800 mb-4 cursor-pointer"
        >
          Suivant
        </button>
      </div>
    </div>
  );
};

export default BottomBtns;
