import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FbOrm } from "../utils/fborm";
import { Page } from "../utils/types";
const BlogModule = () => {
  const navigate = useNavigate();
  const [posts, setPosts] = useState<Page[] | null>(null);
  const seeArticle = (id: string) => {
    navigate(`/page/${id}`);
  };
  useEffect(() => {
    const getPosts = async () => {
      const COLLECTIONS_TO_FETCH = [
        {
          name: "pages",
        },
      ];
      const posts = await FbOrm(COLLECTIONS_TO_FETCH);
      setPosts(posts.pages);
    };
    getPosts();
  }, []);
  return (
    <div className="text-black min-h-[70vh] w-10/12 mx-auto">
      <h1 className="text-2xl font-bold my-4">Notre Blog</h1>
      <div className="grid grid-cols-4 gap-4">
        {posts &&
          posts.map((post) => (
            <div
              key={post.id}
              onClick={() => seeArticle(post.id)}
              className="bg-slate-200 rounded-md p-4 mb-4 hover:scale-110 hover:delay-150 hover:ease-in-out transition-all cursor-pointer"
            >
              {/* <div>
                <img
                  className="w-full h-48 object-cover rounded-md"
                  src="https://plus.unsplash.com/premium_photo-1714051661316-4432704b02f8?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  alt=""
                />
              </div> */}
              <h2 className="text-center py-2">{post.name}</h2>
            </div>
          ))}
      </div>
    </div>
  );
};

export default BlogModule;
