import { LogDiscord } from "../../utils/LogDiscord";
import { Session } from "../../utils/types";

export const getPaymentSession = async (
  price: number,
  name: string,
  paymentMethod: string,
  description: string,
  email: string
) => {
  try {
    const response = await fetch(
      import.meta.env.VITE_API_URL + "/create-checkout-session-appt",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          price,
          name,
          payment: paymentMethod,
          description,
          email,
        }),
      }
    );

    const data = await response.json();

    const { paymentIntentId, clientSecret, url, id } = data as Session;

    if (paymentIntentId) return { paymentIntentId, clientSecret } as Session;
    return { url, id } as Session;
  } catch (error) {
    LogDiscord("error fonction getPaymentSession : " + error, "content.js");
    const errorMessage = [
      "```json",
      " price :",
      price,
      " name :",
      name,
      " paymentMethod :",
      paymentMethod,
      "```",
    ].join(" ");
    LogDiscord(errorMessage, "content.js");
  }
};
