import { LogDiscord } from "../../utils/LogDiscord";
import { handlePaymentProps } from "../../utils/types";
export const alertNewReservation = (reservation: handlePaymentProps) => {
  LogDiscord("temptative de réservation en cours :", "content.js");
  try {
    const variableslogdiscord =
      "```json\n" +
      JSON.stringify({
        initialPrice: reservation.initialPrice,
        codePromo: reservation.codePromo,
        paymentMethod: reservation.paymentMethod,
        serviceName: reservation.service.name,
        serviceId: reservation.service.id,
        client: reservation.client,
        currentUser: reservation.currentUser?.email,
        duration: reservation.duration,
        date: reservation.date,
        creneau: reservation.creneau,
        codecoupon: reservation.codecoupon,
      }) +
      "\n```";
    LogDiscord(variableslogdiscord, "content.js");
  } catch (error) {
    LogDiscord("error fonction logdiscord : " + error, "content.js");
  }
};
