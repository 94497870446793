import { Timestamp } from "firebase/firestore";
import { LogDiscord } from "../../utils/LogDiscord";
import { Client, Service, Session } from "../../utils/types";

export const storeLoggedInStorage = (
  service: Service,
  resDateDebut: Timestamp,
  resDateFin: Date,
  salePrice: number,
  client: Client,
  session: Session,
  paymentMethod: string
) => {
  if (!service || !service.id || !client || !client.id || !session) {
    console.error("Required data is missing");
    LogDiscord("storeLoggedInStorage : Required data is missing", "content.js");
    return; // Stop further execution
  }
  try {
    let description = "";
    if (!client.firstname && !client.lastname) {
      description = " pour " + client.email;
    } else {
      description = " pour " + client.lastname + " " + client.firstname;
    }
    const reservationDetails = {
      idpresta: service.id,
      datedebut: resDateDebut,
      datefin: Timestamp.fromDate(resDateFin),
      name: service.name,
      price: salePrice,
      description,
      clientId: client.id,
      clientEmail: client.email,
      payment: paymentMethod,
      sessionId: session.id || "",
      status: "pending",
      paymentIntentId: session.paymentIntentId || "",
      createdAt: Timestamp.now(),
    };
    //addReservationToFirestore(reservationDetails); removed because it's too inconvenient in production (blocking timeslot for nothing)
    localStorage.setItem(
      "currentReservation",
      JSON.stringify(reservationDetails)
    );
  } catch (error) {
    LogDiscord("error fonction storeLoggedInStorage : " + error, "content.js");
    const errorMessage = [
      "```json",
      " service :",
      JSON.stringify(service),
      " resDateDebut :",
      resDateDebut,
      " resDateFin :",
      resDateFin,
      " salePrice :",
      salePrice,
      " client :",
      JSON.stringify(client),
      " session :",
      JSON.stringify(session),
      " paymentMethod :",
      paymentMethod,
      "```",
    ].join(" ");
    LogDiscord(errorMessage, "content.js");
  }
};
