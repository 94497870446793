import { endOfDay, startOfDay } from "date-fns";
import { WhereFilterOp } from "firebase/firestore";
import { FbOrm } from "./fborm";
import { Appointment, Service } from "./types";

const checkCommonServiceAvailabillity = async (
  serviceId: string,
  dateToCheck: string
) => {
  const CommonServiceAppointments: Appointment[] = [];
  // 1. Get all appointments for the selected date
  const appointments = await getAllApptForDate(dateToCheck);
  // 2. get all the services with the same common service id
  const serviceData = await getServiceData(serviceId);

  if (
    serviceData.prestations.commonId &&
    serviceData.prestations.commonId !== ""
  ) {
    const commonServices = await getCommonServices(
      serviceData.prestations.commonId
    );
    const servicesToCheck = commonServices.prestations.map(
      (service: Service) => service.id
    );
    // 3. get the appointments that have the same common service id

    appointments.appointments.forEach((appointment: Appointment) => {
      if (servicesToCheck.includes(appointment.idpresta)) {
        CommonServiceAppointments.push(appointment);
      }
    });
  }

  // 4. return the appointments that have the same common service id
  return CommonServiceAppointments;
};

const getAllApptForDate = async (date: string) => {
  // return an array of appointments for the selected date
  // get timestamp for the selected date at the start of the day and at the end of the day
  const startDate = startOfDay(new Date(date));
  const endDate = endOfDay(new Date(date));

  const collectionToFetch = [
    {
      name: "appointments",
      where: [
        {
          field: "datedebut",
          operator: ">=" as WhereFilterOp,
          value: startDate,
        },
        { field: "datedebut", operator: "<=" as WhereFilterOp, value: endDate },
      ],
    },
  ];
  const appointments = await FbOrm(collectionToFetch);
  return appointments;
};

const getServiceData = async (serviceId: string) => {
  // return the service data for the selected service id
  const collectionToFetch = [
    {
      name: "prestations",
      id: serviceId,
    },
  ];
  const serviceData = await FbOrm(collectionToFetch);
  return serviceData;
};

const getCommonServices = async (commonServiceId: string) => {
  // return an array of services with the same common service id
  const collectionToFetch = [
    {
      name: "prestations",
      where: [
        {
          field: "commonId",
          operator: "==" as WhereFilterOp,
          value: commonServiceId,
        },
      ],
    },
  ];
  const commonServices = await FbOrm(collectionToFetch);
  return commonServices;
};

export default checkCommonServiceAvailabillity;
